import { useState, useEffect } from "react";
import {
  useDataProvider,
  useRedirect,
  useNotify,
  TextField,
  TopToolbar,
  FunctionField,
  SimpleShowLayout,
  Button,
  WithRecord,
  ListButton,
  EditButton,
  Title,
  ReferenceField,
  Show,
  List,
  useRecordContext,
  ReferenceManyField,
  RichTextField,
  FileField,
  SimpleForm,
  Toolbar,
  SaveButton,
  DeleteWithConfirmButton,
} from "react-admin";
import Grid from "@mui/material/Grid";
import { NewComment } from '../cases/NewComment';

import { Close } from "@mui/icons-material"
import { CommentsIterator } from "../cases/CommentsIterator";
import ShowAttachments from "../cases/ShowAttachments";
import { AssigneeField, PreparerField, CaseStatusField, DateField } from "../erpd";

const CaseToolbar = () => {
  // const { reset } = useFormContext();
  // const notify = useNotify();
  return (
    <div>
      <Button label="Complete Case" variant="contained" />
      <Button label="Close Case" variant="contained" />
      <Button label="Re-Open Case" variant="contained" />
      {/* <SaveButton label="Save" />
      <SaveButton
        label="Save and add"
        mutationOptions={{
          onSuccess: () => {
            // notify("Element created");
            // reset();
          },
        }}
        type="button"
        variant="text"
      /> */}
    </div>
  );
};

const DocField = (props) => {
  const case_ = useRecordContext();
  if (case_.drawing_id) {
    return (
      <ReferenceField reference="drawings" source="drawing_id" link="show">
        <FunctionField
          render={(x) => (
            <span>
              {x.drawingType.name} ({x.version})
            </span>
          )}
        />
      </ReferenceField>
    );
  }
  if (case_.minute_id) {
    return (
      <ReferenceField reference="minutes" source="minute_id" link="show">
        <FunctionField render={(x) => <span>{x.title}</span>} />
      </ReferenceField>
    );
  }
  return <span>-</span>;
};

export const NoteShow = (props) => (
  <Show
    {...props}
    // title={<WithRecord render={(x) => <span>Case: {x.subject}</span>} />}

    actions={
      <TopToolbar>
        {!props.handleClose && <WithRecord
          render={(record) => (
            <ListButton to={`/projects/${record.project_id}/show/notes`} />
          )}
        />}
        <EditButton />
        {props.handleClose && <WithRecord render={() => <DeleteWithConfirmButton redirect={false} mutationOptions={{onSettled: (data, error) => {if (!error) props.handleClose()} }} />} />}
        {props.handleClose && <Button onClick={props.handleClose} startIcon={<Close />} />}
      </TopToolbar>
    }
  >
    <Grid container >
      <Grid item xs={12} sm={12} md={12}>
        <SimpleShowLayout>
          <TextField source="subject" />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <ReferenceField source="project_id" reference="projects" link="show" />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <TextField source="no" label="Note No." />
          {/* <FunctionField label="Subject" render={record => (<><i>#{record.no}</i> {record.subject}</>)} /> */}
        </SimpleShowLayout>
      </Grid>

      {/* <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <ReferenceField label="Case Type" reference="dropdown_names" source="case_type_id" link={false} />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <CaseStatusField />
        </SimpleShowLayout>
      </Grid> */}
      <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <PreparerField />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <DateField source="createdAt" />
        </SimpleShowLayout>
      </Grid>
      {/* <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <AssigneeField />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <DateField source="due_date" showTime={false} />
        </SimpleShowLayout>
      </Grid> */}
      <Grid item xs={12} sm={12} md={12}>
        <SimpleShowLayout>
          <RichTextField source="message" label="Message" />
          <WithRecord render={(x)=><ShowAttachments images={x.images} files={x.attachments} />} />
        </SimpleShowLayout>
      </Grid>

{/*      <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <FileField source="attachments" src="src" title="originalname" target="preview" sx={{
            '& ul': {
              margin:0,
              paddingLeft:0,
              '& li': {
                display:'inline',
                marginRight:10
              }
            }
          }} />
        </SimpleShowLayout>
      </Grid>
*/}
      {/*<Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <DocField label="Reference" />
        </SimpleShowLayout>
      </Grid>*/}

    </Grid>

    {/* <CaseToolbar /> */}
      <ReferenceManyField
        target="case_id"
        reference="comments"
        perPage={1000}
        sort={{ field: "createdAt", order: "ASC" }}
        label={false}
      >
        <SimpleShowLayout>
          <CommentsIterator showStatus reference="comments" />
        </SimpleShowLayout>
        <NewComment isNote={true} />
      </ReferenceManyField>

  </Show >
);