import { useState, useEffect } from "react";
import {
  useRecordContext,
  useListContext,
  useCreate,
  useUpdate,
  useNotify,
  Identifier,
  ReferenceInput,
  SelectInput,
  FileInput,
  SimpleForm as Form,
  FileField,
  RecordContextProvider,
  TextInput,
  required,
  Toolbar,
  SaveButton,
  useRefresh,
} from "react-admin";
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';

import { Box, Button } from "@mui/material";
import { CreateAssignee } from "../erpd";
import { useFormContext } from "react-hook-form";

// import { StatusSelector } from './StatusSelector';

function Reset() {
  const { reset } = useFormContext();
  const record = useRecordContext();

  useEffect(() => {
    reset();
  }, [record]);

  return null;
  // return <input type="button" value="Reset" onClick={()=>reset()} />;
}

function NewCommentToolbar() {
  const {
    reset,
    formState: { isDirty, isSubmitting },
  } = useFormContext();
  const notify = useNotify();
  // const { refetch } = useListContext();
  return (
    <Toolbar>
      <SaveButton
        label="Add Comment"
      // mutationOptions={{
      //     onSuccess: data => {
      //         notify('ra.notification.created', {
      //             type: 'info',
      //             messageArgs: { smart_count: 1 },
      //         });
      //         // redirect(false);
      //         reset();
      //         refetch();
      //     }}
      // }
      // type="button"
      // variant="text"
      />
      <Button onClick={() => reset()} disabled={!isDirty || isSubmitting}>
        Reset
      </Button>
    </Toolbar>
  );
}


function makeid(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

const NewCommentForm = ({ isNote, caseRecord }) => {

  const { getValues, setValue } = useFormContext()
  const [showImagePreview, setShowImagePreview] = useState(false)
  const [imageBlob, setImageBlob] = useState("")
  const [imageFile, setImageFile] = useState()
  const [imageName, setImageName] = useState("")
  document.onpaste = async () => {
    const items = await navigator.clipboard.read().catch((err) => {
      console.error(err);
    });

    for (let item of items) {
      for (let type of item.types) {
        if (type.startsWith("image/")) {
          const imageBlob = await item.getType(type)
          const blobUrl = URL.createObjectURL(imageBlob)
          const fileName = makeid(10) + '.jpg';
          let file = new File([imageBlob], fileName, { type: "image/jpeg" });
          setImageBlob(blobUrl);
          setImageFile(file)
          setImageName(fileName)
          setShowImagePreview(true)
          // const newValue = {
          //   rawFile: file,
          //   title: fileName,
          //   src: blobUrl,
          // }
          // if (!!value) {

          //   value.push(
          //     newValue
          //   )
          // } else {
          //   value = [
          //     newValue
          //   ]
          // }
          // setValue("files", value)
          return true;
        }
      }
    }
  }
  return <>
    <TextInput
      source="message"
      label="New comment"
      variant="filled"
      size="small"
      fullWidth
      multiline
      validate={[required()]}
      style={{ marginTop: "-20px" }}
    />
    <Modal
      sx={{ height: '100%', width: '100%' }}

      open={showImagePreview}
      onClose={() => {
        setShowImagePreview(false)
      }}
    >
      <div style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
        {
          //TODO: Put image preview here
        }
        <Paper
          style={{ maxWidth: "50%", padding: 8 }}
        >
          <h2>Preview</h2>
          <img style={{ maxWidth: "100%" }} src={imageBlob} />
          <div style={{ display: "flex", justifyContent: "end" }}>

            <Button
              onClick={() => {
                let value = getValues("files")
                const newValue = {
                  rawFile: imageFile,
                  title: imageName,
                  src: imageBlob,
                }
                if (!!value) {

                  value.push(
                    newValue
                  )
                } else {
                  value = [
                    newValue
                  ]
                }
                setValue("files", value)
                setShowImagePreview(false)
              }}
            >Paste</Button>
            <Button onClick={() => {
              setShowImagePreview(false)
            }}>Cancel</Button>
          </div>
        </Paper>
      </div>
    </Modal>
    {
      !isNote && (
        // <Box display="flex" justifyContent="space-between" mt={1}>
        //   <span>
        //  {showStatus && (
        // 	<StatusSelector
        // 		status={status}
        // 		setStatus={setStatus}
        // 		sx={{
        // 			marginRight: '1em',
        // 			'& .MuiFilledInput-input': {
        // 				paddingTop: '10px',
        // 			},
        // 			width: 150,
        // 		}}
        // 	/>
        // )}
        <ReferenceInput
          source="addressed_to"
          reference="assignees"
          filter={{ project_id: caseRecord.project_id }}
        >
          <SelectInput
            optionValue="id"
            optionText="name"
            sx={{
              ".MuiFilledInput-root": {
                borderRadius: 2,
                border: "1px solid ",
              },
              ".MuiFilledInput-root::before": {
                borderWidth: 0,
              },
              ".MuiFilledInput-root:hover::before": {
                borderWidth: 0,
              },
              ".MuiFilledInput-root::after": {
                borderWidth: 0,
              },
              ".MuiFilledInput-root:hover::after": {
                borderWidth: 0,
              },
            }}
            label="Re-assign To"
            create={<CreateAssignee resource="assignees" />}
            createLabel="(Add Member...)"
            helperText={false}
            validate={[required()]}
      />
        </ReferenceInput>
      )
      //   </span>
      //  <Button
      //     type="submit"
      //     variant="contained"
      //     color="primary"
      //     // disabled={!text || isLoading}
      //   >
      //     Submit
      //   </Button>
      // </Box>
    }

    <FileInput source="files" lable="Attachments" multiple={true} >
      <FileField source="src" title="title" />
    </FileInput>
    <Reset />

  </>
}

export const NewComment = ({ isNote = false }) => {
  const caseRecord = useRecordContext();
  const [newComment, setNewComment] = useState({});
  // const { refetch } = useListContext();



  const [create, { isLoading }] = useCreate();
  const [update] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();

  if (!caseRecord) return null;

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const handleSubmit = async (data, event) => {
    // event.preventDefault();
    // const formData = new FormData();
    // formData.append("case_id", record.id);
    // formData.append("message", text);
    // formData.append("addressed_to", assignee);
    // files.forEach((f) => formData.append("files", f));
    data.case_id = caseRecord.id;
    await create(
      "comments",
      { data },
      {
        returnPromise: true,
        onSuccess: () => {
          notify("Comment added successfully", { type: "success" });
          refresh();
          setNewComment({});
        },
      }
    );
    return false;
  };
  return (
    <Box mt={1} mb={1}>
      <RecordContextProvider value={newComment}>
        <Form onSubmit={handleSubmit} toolbar={<NewCommentToolbar />}>
          <NewCommentForm isNote={isNote} caseRecord={caseRecord} />
        </Form>
      </RecordContextProvider>
    </Box>
  );
};
