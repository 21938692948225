import { useRecordContext } from "react-admin";
import { Chip } from "@mui/material";
import get from "lodash/get";


export const CaseStatusField = (props) => {
  const record = useRecordContext(props);
  if (!record) return null;
  // default properties
  props = props || {};
  const source = props.source || 'status';

  const fieldValue = get(record, source);
  return (
    <Chip
      label={fieldValue === 'Overdued' ? 'Overdue' : fieldValue}
      size="small"
      color={
        fieldValue === "Open"
          ? "primary"
          : fieldValue === "Completed"
            ? "success"
            : fieldValue === "Overdued"
              ? "error"
              : "default"
      }
    />
  );
};

CaseStatusField.defaultProps = { label: 'Status' };
