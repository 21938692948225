import { useRecordContext } from "react-admin";
import { Typography, TypographyProps } from "@mui/material";
import get from "lodash/get";
import Moment from "react-moment";

const toLocaleStringSupportsLocales = (() => {
  // from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
  try {
    new Date().toLocaleString("i");
  } catch (error) {
    return error instanceof RangeError;
  }
  return false;
})();

export const DateField2 = (props) => {
  const {
    className,
    emptyText,
    locales = "en-MY",
    options,
    showTime = true,
    source,
    ...rest
  } = props;
  const record = useRecordContext(props);
  if (!record) {
    return null;
  }
  const value = get(record, source);
  if (value === null || value === "") {
    return emptyText ? (
      <Typography
        component="span"
        variant="body2"
        className={className}
      // {...sanitizeFieldRestProps(rest)}
      >
        {emptyText}
      </Typography>
    ) : null;
  }

  const date = value instanceof Date ? value : new Date(value);
  let dateOptions = options;
  if (
    typeof value === "string" &&
    value.length <= 10 &&
    !showTime &&
    !options
  ) {
    // Input is a date string (e.g. '2022-02-15') without time and time zone.
    // Force timezone to UTC to fix issue with people in negative time zones
    // who may see a different date when calling toLocaleDateString().
    dateOptions = { timeZone: "UTC" };
  }

  const dateString = showTime
    ? toLocaleStringSupportsLocales
      ? date.toLocaleString(locales, options)
      : date.toLocaleString()
    : toLocaleStringSupportsLocales
      ? date.toLocaleDateString(locales, dateOptions)
      : date.toLocaleDateString();

  const calendarStrings = {
    lastDay: "[Yesterday at] LT",
    sameDay: "[Today at] LT",
    nextDay: "[Tomorrow at] LT",
    lastWeek: "[last] dddd [at] LT",
    nextWeek: "dddd [at] LT",
    sameElse: "DD/MM/YYYY",
  };

  return (
    <Typography
      component="span"
      variant="body2"
      className={className}
      title={dateString}
    //   {...sanitizeFieldRestProps(rest)}
    >
      <Moment date={value} calendar={calendarStrings} />
    </Typography>
  );
};
