import { useCallback } from "react";
import {
  SimpleForm as RaSimpleForm,
  useCreate,
  useUpdate,
  useNotify,
  useRecordContext,
  useResourceContext,
  useRedirect,
  Toolbar,
  SaveButton,
  DeleteButton,
} from "react-admin";

const BotToolbar = ({ confirmTitle = "Delete confirmation", ...props }) => (
  <Toolbar {...props} sx={{ display: "flex", justifyContent: "space-between" }}>
    <SaveButton {...props} />
    <DeleteButton
      mutationMode="pessimistic"
      confirmTitle={confirmTitle}
      {...props}
    />
  </Toolbar>
);

export const SimpleForm = ({
  onSubmit,
  submitOnEnter = false,
  toolbar = <BotToolbar />,
  redirect: redirectTo = null,
  ...props
}) => {
  const resource = useResourceContext();
  const record = useRecordContext();
  const notify = useNotify();
  const redirect = useRedirect();
  const [create] = useCreate();
  const [update] = useUpdate();
  const defaultSubmit = useCallback(
    async (data) => {
      try {
        if (record) {
          data = await update(
            resource,
            { id: record._id, data },
            { returnPromise: true }
          );
          notify("ra.notification.updated", {
            type: "info",
            messageArgs: { smart_count: 1 },
          });
          redirect(redirectTo ?? "list", resource, data.id, data);
        } else {
          data = await create(resource, { data }, { returnPromise: true });
          notify("ra.notification.created", {
            type: "info",
            messageArgs: { smart_count: 1 },
          });
          redirect(redirectTo ?? "edit", resource, data.id, data);
        }
      } catch (error) {
        if (error.body) {
          if (error.body.message)
            notify(error.body.message, { type: "warning" });
          if (error.body.err) return error.body.err;
        }
        return false;
      }
    },
    [create, update, resource, redirect, redirectTo, notify]
  );
  return (
    <RaSimpleForm
      {...props}
      onSubmit={onSubmit ?? defaultSubmit}
      toolbar={toolbar}
      // submitOnEnter
      // warnWhenUnsavedChanges //block useRedirect
    />
  );
};
