import { Controller, useForm } from "react-hook-form";
import {
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Typography,
  Container,
} from "@mui/material";
import { LockOutlined as LockOutlinedIcon } from "@mui/icons-material";
import {
  StyledDiv,
  StyledAvatar,
  StyledForm,
  StyledButton,
  Copyright,
} from "./loginComponents";
import { useNotify, Notification, useAuthProvider } from "react-admin";

export default function SignUp() {
  const authProvider = useAuthProvider();
  const { control, handleSubmit, formState: { isSubmitting, isDirty, isValid } } = useForm();
  const notify = useNotify();

  const submit = (data, e) => {
    if (!window.form1.reportValidity()) return;
    authProvider.register(data)
      .then((e) => {
        notify(e.json.message, { type: "success" });
      })
      .catch((e) => {
        const strarr = [];
        if (e.message) strarr.push(e.message);
        if (e.body?.err) {
          for (const key in e.body.err) {
            strarr.push(e.body.err[key]);
          }
        }
        notify(strarr.join("\n"), { type: "warning", multiLine: true });
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <StyledDiv>
        <StyledAvatar>
          <LockOutlinedIcon />
        </StyledAvatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <StyledForm id="form1" onSubmit={handleSubmit(submit)} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="name"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    required
                    fullWidth
                    autoComplete="name"
                    label="Display Name"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="email"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    required
                    fullWidth
                    autoComplete="email"
                    label="Email Address"
                    type="email"
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Controller
                control={control}
                name="password"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    required
                    fullWidth
                    autoComplete="new-password"
                    label="New Password"
                    type="password"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="password2"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    required
                    fullWidth
                    autoComplete="new-password"
                    label="Confirm Password"
                    type="password"
                  />
                )}
              />
            </Grid> */}
            <Grid item xs={12}>
              <Controller
                control={control}
                name="agree"
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={onChange}
                        checked={value}
                        color="primary"
                        required
                      />
                    }
                    label="I agree to the Terms &amp; Conditions."
                  />
                )}
              />
            </Grid>
          </Grid>
          <StyledButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="submit"
            disabled={isSubmitting}
          >
            Sign Up
          </StyledButton>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="#/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
          <Notification />
        </StyledForm>
      </StyledDiv>
      <Copyright />
    </Container>
  );
}
