import { RichTextInput } from "ra-input-rich-text";
import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  SimpleForm,
  SelectInput,
  required,
  Create,
  FileField,
  FileInput,
  WithRecord,
  TextInput,
  useRecordContext,
  TopToolbar,
  ReferenceInput,
  SimpleShowLayout,
  ReferenceField,
  ListButton,
  Link,
  Labeled,
  useResourceContext,
} from "react-admin";
import { CustomRichTextEditor } from "../erpd";
import { CreateTag } from "../components/CreateTag";

// const transform = (data) => {
//   const form = new FormData();
//   form.append("project_id", data.project_id);
//   form.append("drawing_type", data.drawing_type);
//   form.append("drawing_status", data.drawing_status);
//   form.append("version", data.version);
//   form.append("remarks", data.remarks);
//   form.append("file", data.files.rawFile);
//   console.log("transform", data, form);
//   return form;
// };

function ProjectLink() {
  const record = useRecordContext(); // expect ?source={...}
  const resource = useResourceContext();
  if (record?.project_id && record?.project_name) {
    return (
      <SimpleShowLayout>
        <Labeled label="Project">
          <Link to={`/projects/${record.project_id}/show/${resource}`}>
            {record.project_name}
          </Link>
        </Labeled>
      </SimpleShowLayout>
    );
  }
  if (record?.project_id) {
    return (
      <SimpleShowLayout>
        <ReferenceField
          source="project_id"
          reference="projects"
          link={`/projects/${record.project_id}/show/${resource}`}
        >
          <WithRecord render={(record) => record.name} />
        </ReferenceField>
      </SimpleShowLayout>
    );
  }
  return null;
}

const CreateLayout = () => {
  const record = useRecordContext();

  return (
    <>
      <ProjectLink />
      <SimpleForm>
        <FileInput
          source="uploadPdf"
          label="PDF"
          accept="application/pdf"
          maxSize={100000000}
          validate={required()}
        >
          <FileField source="src" title="title" />
        </FileInput>

        <ReferenceInput
          label="Drawing Type"
          source="drawingType"
          reference="drawing_types"
          filter={{ project_id: record.project_id }}
        >
          <SelectInput
            optionValue="id"
            optionText="name"
            // source="drawing_type"
            // choices={makeChoices(project.drawingTypes)}
            // optionValue="_id"
            sx={{
              ".MuiFilledInput-root": {
                borderRadius: 2,
                border: "1px solid ",
              },
              ".MuiFilledInput-root::before": {
                borderWidth: 0,
              },
              ".MuiFilledInput-root:hover::before": {
                borderWidth: 0,
              },
              ".MuiFilledInput-root::after": {
                borderWidth: 0,
              },
              ".MuiFilledInput-root:hover::after": {
                borderWidth: 0,
              },
            }}
            validate={required()}
            create={<CreateTag resource="drawing_types" />}
            createLabel="(Create...)"
          ></SelectInput>
        </ReferenceInput>

        <TextInput source="version" validate={required()} />

        <ReferenceInput
          label="Drawing Status"
          source="drawingStatus"
          reference="drawing_statuses"
          filter={{ project_id: record.project_id }}
        >
          <SelectInput
            optionValue="id"
            optionText="name"
            validate={required()}
            create={<CreateTag resource="drawing_statuses" />}
            createLabel="(Create...)"
          ></SelectInput>
        </ReferenceInput>
        <CustomRichTextEditor source="remarks" fullWidth />
      </SimpleForm>
    </>
  );
};

export const DrawingCreate = () => {
  return (
    <Create
      actions={
        <TopToolbar style={{ minHeight: 20 }}>
          <WithRecord
            render={(record) => (
              <ListButton to={`/projects/${record.project_id}/show/drawings`} />
            )}
          />
        </TopToolbar>
      }
    >
      <CreateLayout />
    </Create>
  );
};
