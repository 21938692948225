import { useState, useEffect, useCallback, Fragment } from "react";
import { Box, Typography } from "@mui/material";
import ContentAdd from "@mui/icons-material/Add";
import {
  ReferenceInput,
  List,
  Datagrid,
  TextField,
  FunctionField,
  SearchInput,
  TextInput,
  useListContext,
  useRecordContext,
  SelectInput as RaSelectInput,
  TopToolbar,
  FilterButton,
  ExportButton,
  CreateButton,
  Button,
  useDataProvider,
  useRedirect,
  CloneButton,
  DateInput,
  AutocompleteInput,
  SelectArrayInput,
  ReferenceField,
  ReferenceArrayInput,
  SelectColumnsButton,
  BulkDeleteWithConfirmButton,
  BulkExportButton,
  Empty,
  defaultExporter,
  useListController,
} from "react-admin";
import {
  caseStatusEnum,
  defaultfilterCaseStatus,
  DateField,
  DateField2,
  AssigneeField,
  PreparerField,
  CaseStatusField,
} from "../erpd";

import CommonDataGrid from "../components/CommonDataGrid";
import { customExporter } from "../utils/customExporter";

const caseFromToEnum = [
  { id: "By Me", name: "By Me" },
  { id: "To Me", name: "To Me" },
  { id: "To My Department", name: "To My Department" },
  { id: "To Everyone", name: "To Everyone" },
  { id: "Other", name: "Other" }, // todo: remove this for non-admin
];

function SelectInput(props) {
  return (
    <RaSelectInput
      sx={{
        ".MuiFilledInput-root": {
          borderRadius: 2,
          border: "1px solid ",
        },
        ".MuiFilledInput-root::before": {
          borderWidth: 0,
        },
        ".MuiFilledInput-root:hover::before": {
          borderWidth: 0,
        },
        ".MuiFilledInput-root::after": {
          borderWidth: 0,
        },
        ".MuiFilledInput-root:hover::after": {
          borderWidth: 0,
        },
      }}
      {...props}
    />
  );
}

const ListActions = ({ project }) => {
  //   const createCaseButton = project? <CloneButton
  //       variant="contained"
  //       resource="cases"
  //       label="CREATE"
  //       record={{ project_id: project.id }}
  //       icon={<ContentAdd />}
  //       sx={{ marginLeft: 2 }}
  //     /> : null;
  return (
    <TopToolbar>
      <SelectColumnsButton />
      <FilterButton />
      <ExportButton />
      {/* { createCaseButton } */}
    </TopToolbar>
  );
};

function DocField(props) {
  const case_ = useRecordContext(props);
  if (case_.drawing_id) {
    return <ReferenceField reference="drawings" source="drawing_id" link={false} />;
  }
  if (case_.minute_id) {
    return <ReferenceField reference="minutes" source="minute_id" link={false} />;
  }
  if (case_.document_id) {
    return <ReferenceField reference="minutes" source="document_id" link={false} />;
  }
};

function showCaseOrPdf(id, resource, record) {
  const filter = "notefilter=" + encodeURIComponent(`{"no": ${record.no}}`) + `&pg=${record.annotations?.at(0)?.page}`;
  if (record.drawing_id) {
    return `/drawings/${record.drawing_id}/show?${filter}`;
  }
  if (record.minute_id) {
    return `/minutes/${record.minute_id}/show?${filter}`;
  }
  if (record.document_id) {
    return `/minutes/${record.document_id}/show?${filter}`;
  }
  return `/notes/${id}/show`;
}

const CaseTable = (props) => {
  const showProject = props.showProject || false;
  // let list = useListContext();
  // let redirect = useRedirect();
  // const project = useRecordContext();

  return (
    <CommonDataGrid
      bulkActionButtons={
        <>
          <BulkExportButton />
          <BulkDeleteWithConfirmButton />
        </>
      }
      omit={["createdAt"]}
      rowClick={showCaseOrPdf}
    >
      <TextField source="no" label="No." />
      {/* { showProject && <TextField source="project.name" label="Project" /> } */}
      {/* <TextField source="case_type" label="Type" /> */}
      {/* <ReferenceField source="case_type" reference="case_types">
        <TextField source="name" label="Case Type" />
      </ReferenceField> */}
      {/* <ReferenceField label="Case Type" reference="dropdown_names" source="case_type_id" link={false} /> */}
      {/* <TextField source="caseType.name" label="Case Type" /> */}
      <TextField source="subject" />
      <DocField label="PDF" />
      {/* preparer (role_Id) is equal to createdBy (user_id) */}
      <PreparerField />
      {/* <AssigneeField /> */}
      <TextField source="num_comments" label="Comments" />
      {/* <TextField source="status" /> */}
      {/* <CaseStatusField /> */}
      {/* <FunctionField source="status" render={record => {
        return <span
          style={{
            color:
              record.status === 'Open' ? '#2972FF' :
                record.status === 'Completed' ? '#1B9982' :
                  '#DE1515'
          }}
        >{record.status}</span>
      }} /> */}
      {/* <DateField source="due_date" label="Due Date" showTime={false} /> */}
      <DateField source="createdAt" label="Created At" />
      <DateField source="updatedAt" label="Updated At" />
    </CommonDataGrid>
  );
};

// Define constants for custom export
// Header name should follow according to the grid title
const headerTemplate = "ID,Subject,Reference,Preparer,Comments,Updated At";

// Parse in the record arrangement as the display
// rowTemplate for CSV
const rowTemplate = (record) => {
  return `${record.no},${record.subject},${
    record.drawing_id ? "Drawing" : record.minute_id ? "Minute" : "Case"
  },${record.createdBy.name},${record.num_comments || ""},${record.updatedAt}`;
};

// 2 types of case list: of single project (default filter project, can create case), of all projects (can filter project)
export const NoteList = (props) => {
  const project = useRecordContext(); // project is null when list all projects
  const showProject = project == null;
  // if (!project || !project.id) return null;

  const filters = [
    <SearchInput source="no" placeholder="No." alwaysOn style={{ width: "100px" }} />,
    // <SelectInput source="status" choices={caseStatusEnum} emptyText="Show All" alwaysOn />,
    // showProject? null : <ReferenceInput source="case_type_id" reference="case_types" filter={{project_id: project?.id}} />,
    <SearchInput source="subject" placeholder="Subject" alwaysOn />,
    <ReferenceInput
      label="Preparer"
      source="createdBy"
      reference="users"
      filter={{ project_id: project?.id }}
      sort={{ field: "name", order: "ASC" }}
      alwaysOn
    >
      <SelectInput optionValue="id" optionText="name" emptyText="Show All" label="Preparer"/>
    </ReferenceInput>,
  ];

  return (
    <List
      {...props}
      disableSyncWithLocation
      resource="notes"
      filters={filters}
      // filterDefaultValues={{ status: defaultfilterCaseStatus }}
      filter={showProject ? {} : { project_id: project?.id }}
      sort={{ field: "updatedAt", order: "DESC" }}
      // perPage={21}
      hasCreate={false}
      actions={<ListActions project={project} />}
      // empty={<Empty hasCreate={false}/>}
      empty={false}
      exporter={(records, fetchRelatedRecords) =>
        customExporter(
          records,
          fetchRelatedRecords,
          headerTemplate,
          rowTemplate,
          "notes"
        )
      }
      storeKey={`note_list_${project?.id}`}
    >
      <CaseTable showProject={showProject} />
    </List>
  );
};
