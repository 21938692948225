import { fetchUtils } from "react-admin";

// return {status = 2XX, headers, body = data-string, json = data-object}
// catch HttpError {name = 'HttpError', message (eg Bad Request), status (!=2XX), body = data-object, stack}
function postData(url, formDataOrObject = null, method = "POST") {
  return fetchUtils
    .fetchJson(url, {
      method,
      body:
        formDataOrObject != null &&
          typeof formDataOrObject === "object" &&
          !(formDataOrObject instanceof FormData)
          ? JSON.stringify(formDataOrObject)
          : formDataOrObject,
    });
}

export default function CreateAuthProvider(apiUrl = "") {
  
  return {
    _identity: null,

    // login(object)
    async login({ username, password, remember }) {
      const { status, headers, body, json } = await postData(apiUrl + "/login", { username, password, remember });
      console.log('login', status, headers, body, json);
      localStorage.setItem('login', Date.now);
      this._identity = { id: json.doc._id, fullName: json.doc.name, avatar: json.doc.avatar, timestamp: Date.now() };
    },

    async logout() {
      localStorage.removeItem('login');
      try {
        await postData(apiUrl + "/logout");
      } catch {
      }
    },

    // useCheckAuth(object)
    async checkAuth() {
      if (localStorage.getItem('login') === null) {
        throw new Error("Not loged in.");
      }
    },

    // status === 403 // forbidden does not trigger login prompt
    // params: { message: string, status: number, body: Object }
    async checkError({ status }) {
      if (status === 401) {
        throw new Error('Unauthorized user');
      }
    },

    async getIdentity() {
      if (this._identity == null || Date.now() - this._identity.timestamp > 5 * 60_000) {
        const data = await postData(apiUrl + '/profile', null, 'GET');
        this._identity = { id: data.json.doc._id, fullName: data.json.doc.name, 
          avatar: data.json.doc.avatar, timestamp: Date.now() };
      }
      return this._identity;
    },

    // params: usePermissions(object)
    async getPermissions(params) {
      if (localStorage.getItem('login') === null) {
        return {
          canCreateProject: () => false,
          isProjectAdmin: () => false,
          canManageUser: () => false,
        };
      }
      let doc = {};
      try {
        const { json } = await postData(apiUrl + '/profile', null, 'GET');
        doc = json.doc;
      } catch { }
      return {
        ...doc,
        canCreateProject() {
          return !!(doc.is_super_admin || doc.is_admin);
        },
        isProjectAdmin(projectId) {
          return !!doc.projects?.find(x => x.id===projectId && x.role === 'Admin');
        },
        canManageUser() {
          return !!doc.is_super_admin
        }
      }
      // const data = JSON.parse(localStorage.getItem(STORAGE_NAME));
      // const obj = data
      //   ? {
      //     ...data.permissions,
      //     getProjectRole: function (projectId) {
      //       return this.projects.find((x) => x._id === projectId)?.role;
      //     },
      //     isProjectAdmin: async function (projectId) {
      //       const result = await postData(`${apiUrl}/${projectId}/${data.identity.id}`, '', 'GET');
      //       return (result?.doc === 'Admin');
      //       // return (
      //       //   this.projects.find((x) => x._id === projectId)?.role === "Admin"
      //       // );
      //     },
      //     canCreateProject() { return (data.permissions.isSuper || data.permissions.isAdmin) }
      //   }
      //   : {};
      // return obj;
    },

    // getRoles: async () => [],

    //////////////////////////////////////////////////////
    register: (data) => postData(apiUrl + '/register', data),
    forgotPassword: (email) => postData(apiUrl + '/password/reset', { email }),
    resetPassword: (_id, token, password) =>
      postData(apiUrl + "/password/resetchange", {_id, token, password }),
  }
}

// const COOKIE_NAME = "token";
// const STORAGE_NAME = "session";

// function getCookie(key) {
//   return document.cookie
//     .split("; ")
//     .find((row) => row.startsWith(key + "="))
//     .split("=")[1];
// }

// function parseJwt(token) {
//   const base64Url = token.split(".")[1];
//   const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
//   const jsonPayload = decodeURIComponent(
//     window.atob(base64)
//       .split("")
//       .map(function (c) {
//         return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
//       })
//       .join("")
//   );
//   return JSON.parse(jsonPayload);
// }