import { useRecordContext } from "react-admin";
import Chip from "@mui/material/Chip";
import get from "lodash/get";

export const UserStatusField = (props) => {
  const { className, emptyText, options, source, ...rest } = props;
  const record = useRecordContext(props);
  if (!record) {
    return null;
  }
  // const value = get(record, source);
  const value = get(record, "is_active") ? "Active" : "Inactive";
  let color = "default";
  switch (value) {
    case "Inactive":
    case "Pending":
      color = "warning";
      break;
    case "Approved":
      color = "success";
      break;
    case "Invited":
      color = "success";
      break;
    case "Active":
      color = "success";
      break;
    case "Suspended":
      color = "error";
      break;
  }
  return value ? <Chip label={value} color={color} size="small" sx={{color:'white !important'}} /> : null;
};
