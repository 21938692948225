import {
    DateField as RaDateField,
    useRecordContext,
} from "react-admin";
import { Typography} from '@mui/material';
import get from "lodash/get";

function formatdt(isoDateString, showTime) {
    try {
        if (showTime === false)
            return new Date(isoDateString).toLocaleString('en-MY', {day:'numeric',month:'numeric',year:'numeric'});
        return new Date(isoDateString).toLocaleString('en-MY');
    } catch {
        return isoDateString;
    }
}

// same day show time "02:59", otherwise show date "1 Jan".
export const DateField = (props) => {
    const record = useRecordContext(props);
    if (!record || !props.source) {
        return null;
    }
    const fieldValue = get(record, props.source);
    const attrs = {
        locales: props.locales ?? 'en-MY',
        title: props.title ?? formatdt(fieldValue, props.showTime),
    };
    const today = new Date();
    if (today.toDateString() === new Date(fieldValue).toDateString()) {
        if (props.showTime === false) 
        return <Typography component="span" variant="body2" title={attrs.title}>Today</Typography>;
        return (
            <RaDateField
                {...props}
                {...attrs}
                showTime
                showDate={false}
                options={{ timeStyle: "short", hour12: true }}
            />
        );
    } else if (today.getFullYear() === new Date(fieldValue).getFullYear()) {
        return (<RaDateField {...props} {...attrs} options={{ day: "numeric", month: "numeric" }} />); // numeric or 2-digit
        // return (<RaDateField {...props} {...attrs} options={{ day: "numeric", month: "short" }} />); // 2 Jan
    } else {
        return (<RaDateField {...props} {...attrs} />);
    }
};