import { Dialog, DialogContent, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function DialogNoTitle({
  open,
  onClose,
  width = "md",
  title,
  ...props
}) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth={width} fullWidth={true}>
      <div style={{ position: "absolute", right: 0 }}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      {props.children}
    </Dialog>
  );
}
