import "./App.css";
import { lazy, Suspense } from "react";
import { Route } from "react-router-dom";
import { Admin, Resource, CustomRoutes, Loading } from "react-admin";
import {
  Folder as FolderIcon,
  People as PeopleIcon,
} from "@mui/icons-material";

import { ProjectList } from "./projects/ProjectList";
import { ProjectCreate } from "./projects/ProjectCreate";
import { ProjectEdit } from "./projects/ProjectEdit";
import { ProjectShow } from "./projects/ProjectShow";
import { UserList } from "./users/UserList";
import { UserCreate } from "./users/UserCreate";
import { UserEdit } from "./users/UserEdit";
import { CaseCreate } from "./cases/CaseCreate";
import { CaseEdit } from "./cases/CaseEdit";
import { CaseShow } from "./cases/CaseShow";
import { CaseList } from "./cases/CaseList";
import { NoteEdit } from "./notes/NoteEdit";
import { NoteShow } from "./notes/NoteShow";
import { Layout } from "./layout/Layout";
import Dashboard from "./dashboard/Dashboard";
import authProvider from "./providers/authProvider";
import dataProvider from "./providers/dataProvider";
import SignIn from "./login/SignIn";
import { DrawingCreate } from "./drawings/DrawingCreate";
import { DrawingEdit } from "./drawings/DrawingEdit";
// import DrawingShow from "./drawings/DrawingShow";
import { MinuteCreate } from "./minutes/MinuteCreate";
import { MinuteEdit } from "./minutes/MinuteEdit";
// import MinuteShow from "./minutes/MinuteShow";
import { MemberCreate } from "./members/MemberCreate";
import { MemberEdit } from "./members/MemberEdit";
import { Profile } from "./profile/Profile";
import SignUp from "./login/SignUp";
import Forgot from "./login/Forgot";
import Reset from "./login/Reset";
import { GroupEdit } from "./groups/GroupEdit";
import { API_URL } from "./config/domain";

const DrawingShow = lazy(() => import("./drawings/DrawingShow"));
const MinuteShow = lazy(() => import("./minutes/MinuteShow"));

const LazyDrawingShow = () => (
  <Suspense fallback={<Loading />}>
    <DrawingShow />
  </Suspense>
);
const LazyMinuteShow = () => (
  <Suspense fallback={<Loading />}>
    <MinuteShow />
  </Suspense>
);

const App = () => (
  <Admin
    layout={Layout}
    authProvider={authProvider(API_URL)}
    dataProvider={dataProvider(API_URL)}
    dashboard={Dashboard}
    loginPage={SignIn}
    // requireAuth //signup,reset-pwd wont work
    disableTelemetry
  >
    {(permissions) => (
      <>
        {permissions && (
          <>
            <Resource
              name="projects"
              list={Dashboard}
              edit={ProjectEdit}
              create={permissions.canCreateProject() ? ProjectCreate : null}
              show={ProjectShow}
              icon={FolderIcon}
              recordRepresentation="name"
            >
              (permissions.canCreateProject() &&{" "}
              <Route path="all" element={<ProjectList />} />)
            </Resource>
            <Resource
              name="cases"
              list={CaseList}
              edit={CaseEdit}
              create={CaseCreate}
              show={CaseShow}
            />
            <Resource name="notes" edit={NoteEdit} show={NoteShow} />
            {permissions.canManageUser() && (
              <Resource
                name="users"
                list={UserList}
                edit={UserEdit}
                create={UserCreate}
                icon={PeopleIcon}
                recordRepresentation="name"
              />
            )}
            <Resource
              name="drawings"
              create={DrawingCreate}
              edit={DrawingEdit}
              show={LazyDrawingShow}
              recordRepresentation={record=>`${record.drawingType.name} (${record.version})`}
            />
            <Resource
              name="minutes"
              create={MinuteCreate}
              edit={MinuteEdit}
              show={LazyMinuteShow}
              recordRepresentation="title"
            />
            <Resource name="members" create={MemberCreate} edit={MemberEdit} />
            <Resource name="groups" edit={GroupEdit} />
            <Resource name="case_types" recordRepresentation="name" />
            <Resource name="user_names" recordRepresentation="name" />
            <Resource name="dropdown_names" recordRepresentation="name" />
            <Resource name="assignees" recordRepresentation="name" />
            <Resource
              name="role_names"
              recordRepresentation={(record) =>
                `${
                  record.role === "Everyone"
                    ? "**"
                    : record.role === "Group"
                    ? "*"
                    : ""
                }${record.name}`
              }
            />
            <CustomRoutes>
              <Route path="/settings" element={<Profile />} />,
            </CustomRoutes>
          </>
        )}
        <CustomRoutes noLayout>
          <Route path="/signup" element={<SignUp />} />,
          <Route path="/forgot" element={<Forgot />} />,
          <Route path="/reset" element={<Reset />} />,
        </CustomRoutes>
      </>
    )}
  </Admin>
);

export default App;
