import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  CssBaseline,
  TextField,
  Link,
  Grid,
  Typography,
  Container,
} from "@mui/material";
import { LockOutlined as LockOutlinedIcon } from "@mui/icons-material";
import { useNotify, Notification, useAuthProvider } from "react-admin";
import {
  StyledDiv,
  StyledAvatar,
  StyledForm,
  StyledButton,
  Copyright,
} from "./loginComponents";

export default function ResetPassword() {
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const authProvider = useAuthProvider();
  const notify = useNotify();
  const submit = (e) => {
    e.preventDefault();
    if (!e.currentTarget.reportValidity()) return;
    setIsLoading(true);
    authProvider.resetPassword(searchParams.get("id"), searchParams.get("token"), password)
      .then((e) => {
        notify("Please login with new password", { type: "success" });
      })
      .catch((e) => {
        const strarr = [];
        if (e.message) strarr.push(e.message);
        if (e.body?.err) {
          for (const key in e.body.err) {
            strarr.push(e.body.err[key]);
          }
        }
        notify(strarr.join("\n"), { type: "warning", multiLine: true });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <StyledDiv>
        <StyledAvatar>
          <LockOutlinedIcon />
        </StyledAvatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <StyledForm onSubmit={submit} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password2"
            label="Confirm Password"
            type="password"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
          />
          <StyledButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="submit"
            disabled={isLoading}
          >
            Submit
          </StyledButton>
          <Grid container>
            <Grid item xs>
              <Link href="#/login" variant="body2">
                Login
              </Link>
            </Grid>
            <Grid item>
              <Link href="#/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
          <Notification />
        </StyledForm>
      </StyledDiv>
      <Copyright />
    </Container>
  );
}
