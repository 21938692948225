import { RichTextInput } from "ra-input-rich-text";
import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  SimpleForm,
  SelectInput,
  required,
  Create,
  FileField,
  FileInput,
  WithRecord,
  TextInput,
  useRecordContext,
  useNotify,
  TopToolbar,
  ReferenceInput,
  SimpleShowLayout,
  ReferenceField,
  ListButton,
  Link,
  Labeled,
  useResourceContext,
  ImageInput,
  Edit,
  ImageField,
  NumberField,
  ArrayInput,
  SimpleFormIterator,
  TabbedForm,
  FormTab,
  BooleanInput,
  PasswordInput,
  useGetOne,
  RichTextField,
  AutocompleteInput,
  useCreate,
  useDataProvider,
} from "react-admin";
import { CreateTag } from "../components/CreateTag";
import { CustomRichTextEditor } from "../erpd";

// const transform = (data) => {
//   const form = new FormData();
//   form.append("project_id", data.project_id);
//   form.append("title", data.title);
//   form.append("minute_status", data.minute_status);
//   // form.append("version", data.version);
//   form.append("remarks", data.remarks);
//   form.append("file", data.files.rawFile);
//   // console.log("transform", data, form);
//   return form;
// };

// function makeChoices(array) {
//   return array.map((x) => ({
//     id: x.name ? x.name : x,
//     name: x.name ? x.name : x,
//   }));
// }

function ProjectLink() {
  const record = useRecordContext(); // expect ?source={...}
  const resource = useResourceContext();
  if (record?.project_id && record?.project_name) {
    return (
      <SimpleShowLayout>
        <Labeled label="Project">
          <Link to={`/projects/${record.project_id}/show/${resource}`}>
            {record.project_name}
          </Link>
        </Labeled>
      </SimpleShowLayout>
    );
  }
  if (record?.project_id) {
    return (
      <SimpleShowLayout>
        <ReferenceField
          source="project_id"
          reference="projects"
          link={`/projects/${record.project_id}/show/${resource}`}
        >
          <WithRecord render={(record) => record.name} />
        </ReferenceField>
      </SimpleShowLayout>
    );
  }
  return null;
}

const CreateLayout = () => {
  const record = useRecordContext();
  const notify = useNotify();

  const onSuccess = (data) => {
    notify("New minute is created.", { type: "success" });
  };

  const [create] = useCreate();
  const postSave = async (data) => {
    // Remove fontSize from data before sending to the server
    const { fontSize, ...postData } = data;
    // Update the data object
    data = postData;
    await create("minutes", { data }, { returnPromise: true, onSuccess });
  };
  return (
    <>
      <ProjectLink />
      <SimpleForm onSubmit={postSave}>
        <FileInput
          source="uploadPdf"
          label="PDF File"
          accept="application/pdf"
          maxSize={100000000}
          validate={required()}
        >
          <FileField source="src" title="title" />
        </FileInput>
        <TextInput source="title" />

        <ReferenceInput
          label="Minute Status"
          source="minuteStatus"
          reference="minute_statuses"
          filter={{ project_id: record.project_id }}
        >
          <SelectInput
            optionValue="id"
            optionText="name"
            sx={{
              ".MuiFilledInput-root": {
                borderRadius: 2,
                border: "1px solid ",
              },
              ".MuiFilledInput-root::before": {
                borderWidth: 0,
              },
              ".MuiFilledInput-root:hover::before": {
                borderWidth: 0,
              },
              ".MuiFilledInput-root::after": {
                borderWidth: 0,
              },
              ".MuiFilledInput-root:hover::after": {
                borderWidth: 0,
              },
            }}
            validate={required()}
            create={<CreateTag resource="minute_statuses" />}
            createLabel="(Create...)"
          ></SelectInput>
        </ReferenceInput>

        <CustomRichTextEditor source="remarks" fullWidth />
      </SimpleForm>
    </>
  );
};

export const MinuteCreate = () => {
  return (
    <Create
      actions={
        <TopToolbar style={{ minHeight: 20 }}>
          <WithRecord
            render={(record) => (
              <ListButton to={`/projects/${record.project_id}/show/minutes`} />
            )}
          />
        </TopToolbar>
      }
      // transform={transform}
    >
      <CreateLayout />
    </Create>
  );
};
