import { useState, useEffect } from "react";
import {
  DateInput,
  SelectInput,
  SimpleForm,
  useDataProvider,
  useRedirect,
  AutocompleteInput,
  useNotify,
  // useGetIdentity,
  Title,
  Edit,
  WithRecord,
  useRecordContext,
  SimpleShowLayout,
  ReferenceField,
  TopToolbar,
  ShowButton,
  ListButton,
  ReferenceInput,
  required,
  FileInput,
  FileField,
  Toolbar,
  SaveButton,
  DeleteButton,
  ToolbarClasses,
  Button,
} from "react-admin";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  TextInput,
  caseStatusEnum,
  CreateTag,
  CreateAssignee,
  CustomRichTextEditor,
  Dialog,
} from "../erpd";

// const Title = ({ record }) => {
//   if (!record) return null;
//   return <span>Case: {record.subject}</span>;
// };

function howManyDaysFromToday(yyyymmdd, ISOfrom) {
  const now = ISOfrom ? new Date(ISOfrom) : new Date();
  // UTC to local
  const localToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const localDate = new Date(yyyymmdd);
  return (localDate - localToday) / 86400000;
}

//form validation function
const updateCaseValidation = (values) => {
  let errors;

  errors = {};

  //assignee checks
  if (!values.assignee) errors.assignee = "Assignee must not be empty";

  //subject checks
  if (!values.subject) errors.subject = "Subject must not be empty";
  else if (values.subject.length < 1)
    errors.subject = "Subject must not be empty";

  //case type checks
  if (!values.case_type_id) errors.case_type_id = "Case type must not be empty";

  //due date checks
  if (!values.due_date) errors.due_date = "Due date must not be empty";
  if (howManyDaysFromToday(values.due_date, values.createdAt) < 0) {
    // const d = new Date(values.createdAt).toLocaleDateString();
    errors.due_date = `Due date must be greater than or equal to creation date`; // ${d}
  }

  //status checks
  if (!values.status || values.status === "")
    errors.status = "Status must not be empty";

  return errors;
};

// const CaseEditRoot = (props) => {
//   const [currCase, setCurrCase] = useState(null); //current case object
//   const [currProject, setCurrProject] = useState(null); //current project object
//   const [availAssignees, setAvailAssignees] = useState([]); //available assignees to choose from
//   const [caseTypes, setCaseTypes] = useState(null); //case types
//   let provider; //data provider
//   let url; //url from browser
//   let projectId; //project id
//   let caseId; //case id
//   let redirect; //redirect hook
//   let notify; //notify hook
//   let tempAvailAssignees; //temporary available assignees
//   let identity; //use identity

//   provider = useDataProvider();
//   redirect = useRedirect();
//   notify = useNotify();
//   identity = useGetIdentity();

//   //parse url and get current case
//   url = window.location.href;
//   projectId = url.substring(url.indexOf("projectId=") + "projectId=".length);
//   caseId = url.substring(
//     url.indexOf("cases/") + "cases/".length,
//     url.indexOf("/edit")
//   );

//   useEffect(() => {
//     //get project
//     provider
//       .getOne("projects", { id: projectId })
//       .then((data) => {
//         let case_types_temp;

//         //audit data to be used in form
//         case_types_temp = [];
//         data.data.case_types.map((type, i) =>
//           case_types_temp.push({ id: i, name: type })
//         );
//         setCaseTypes(case_types_temp);

//         setCurrProject(data.data);
//       })
//       .catch((error) => {
//         console.log(error);
//         alert("unexpected error");
//         redirect("/projects");
//       });

//     //get case
//     provider
//       ._cases_getCase({ projectId, caseId })
//       .then((data) => {
//         if (data.error) {
//           console.log(data.error);
//           alert("Data get error");
//           redirect("/projects");
//         }

//         setCurrCase(data.data.doc);
//         console.log("curr case", data.data.doc);
//       })
//       .catch((e) => {
//         console.log(e);
//         alert("unexpected error");
//         redirect("/projects");
//       });

//     //get project members to populate assignees
//     tempAvailAssignees = [];
//     provider
//       ._project_getMembersFromProject({ projectId })
//       .then((members) => {
//         members.data.doc.map((member) => {
//           //modify member object before push to autocomplete options
//           let modifiedMember;

//           modifiedMember = member;
//           modifiedMember.id = member._id;
//           delete modifiedMember._id;

//           tempAvailAssignees.push(modifiedMember);
//         });
//         //set assignee state
//         setAvailAssignees(tempAvailAssignees);
//       })
//       .catch((e) => console.log("error get assignees ", e));
//   }, []);

//   useEffect(() => {
//     console.log("iden ", identity);
//   }, [identity]);

//   return (
//     <div>
//       {currCase && <Title title={`Case: ${currCase?.subject}`} />}
//       <div style={{ padding: "10px 0 0 10px" }}>
//         <Typography variant="h4">Case edit</Typography>
//       </div>
//       {currCase && currProject ? (
//         <SimpleForm
//           validate={updateCaseValidation}
//           defaultValues={{
//             subject: currCase.subject,
//             due_date: currCase.due_date,
//           }}
//           onSubmit={(values) => {
//             let payload;

//             payload = {
//               assignee: values.assignee,
//               subject: values.subject,
//               case_type: values.case_type,
//               due_date: values.due_date,
//               status: "Closed", //UNCONFIRMED
//             };
//             provider
//               ._cases_updateCase({ projectId, caseId, body: payload })
//               .then((data) => {
//                 //expected errors handled here
//                 if (data.error) console.log("error, ", data.error);
//                 else {
//                   notify("Case update success", { type: "success" });
//                   redirect("/projects");
//                 }
//               })
//               .catch((e) => {
//                 notify("Case update failure", { type: "warning" });
//                 console.log("an error occured unhandled by server ", e);
//               });

//             console.log("submitting ", values);
//           }}
//         >
//           <TextInput
//             source="subject"
//             disabled={currCase.preparer.name !== identity.identity.fullName}
//           />

//           {availAssignees ? (
//             <AutocompleteInput
//               source="assignee"
//               choices={availAssignees}
//               defaultValue={currCase.assignee._id}
//               disabled={currCase.preparer.name !== identity.identity.fullName}
//             />
//           ) : (
//             "loading assignees...."
//           )}

//           <SelectInput
//             source="case_type"
//             choices={caseTypes}
//             optionValue="name"
//             defaultValue={caseTypes[0].name}
//             disabled={currCase.preparer.name !== identity.identity.fullName}
//           />

//           <DateInput
//             source="due_date"
//             disabled={currCase.preparer.name !== identity.identity.fullName}
//           />

//           <AutocompleteInput
//             source="status"
//             choices={caseStatusEnum}
//             optionValue="name"
//             defaultValue={caseStatusEnum[0].name}
//             disabled={currCase.assignee.name !== identity.identity.fullName}
//           />
//         </SimpleForm>
//       ) : null}
//     </div>
//   );
// };

// export default CaseEditRoot;

const EditLayout = () => {
  const record = useRecordContext();
  if (!record) return null;
  const lockedCase = ['closed', 'completed'].indexOf(record.status.toLowerCase());
  const caseStatusOptions =  lockedCase == -1 ? caseStatusEnum: caseStatusEnum.slice(-2);
  return (
    <>
      <SimpleShowLayout>
        <ReferenceField source="project_id" reference="projects" link="show" />
      </SimpleShowLayout>
      <SimpleForm validate={updateCaseValidation}>
        <Grid container>
          <Grid item xs={12}>
            <TextInput
              source="subject"
              fullWidth
              // disabled={currCase.preparer.name !== identity.identity.fullName}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <ReferenceInput
              source="case_type_id"
              reference="case_types"
              filter={{ project_id: record.project_id }}
              perPage={100}
            >
              <SelectInput
                label="Case Type"
                optionValue="id"
                optionText="name"
                validate={required()}
                create={<CreateTag resource="case_types" />}
                createLabel="(Create...)"
              ></SelectInput>
            </ReferenceInput>
          </Grid>
          <Grid item md={4} xs={12}>
            <DateInput
              source="due_date"
              // disabled={currCase.preparer.name !== identity.identity.fullName}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <SelectInput
              source="status"
              optionValue="name"
              choices={caseStatusOptions}
              // defaultValue={caseStatusEnum[0].name}
              // disabled={currCase.assignee.name !== identity.identity.fullName}
              validate={required()}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomRichTextEditor source="message" />
            <FileInput source="attachments" multiple>
              <FileField source="src" title="title" target="preview" />
            </FileInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </>
  );
};

export const CaseEdit = (props) => {
  return (
    // <CaseEditRoot {...props} />
    <Edit
      redirect="show"
      mutationMode="pessimistic"
      // {...props}
      // title={<WithRecord render={(x) => <span>Case: {x.subject}</span>} />}
      actions={
        <TopToolbar style={{ minHeight: 20 }}>
          <WithRecord
            render={(record) => (
              <ListButton to={`/projects/${record.project_id}/show`} />
            )}
          />
          <ShowButton />
        </TopToolbar>
      }
    >
      <EditLayout />
    </Edit>
  );
};
