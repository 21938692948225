import { DatagridConfigurable } from "react-admin";
const CommonDataGrid = (props) => {
  return <DatagridConfigurable
    rowClick="show"
    sx={{
      border: 1,
      borderRadius: 1,
      borderColor: '#888888',
      '.MuiTableCell-head': {
        backgroundColor: '#e7e6e6',
        borderStyle: 'solid',
        borderBottomWidth: 1,
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderColor: '#888888'
      },
      '.MuiTableCell-head span': {
        fontWeight: 'bold',
        color: '#555555'
      },
      '.MuiTableRow-root:nth-of-type(even)': {
        backgroundColor: '#f6f6f6',
      },
      '.MuiTableRow-root:nth-of-type(odd)': {
        backgroundColor: '#ffffff',
      },
      '.MuiTableCell-body': {
        borderWidth: 0,
        color: '#555555'
      },
      '.MuiTableCell-body span': {
        fontWeight: 'bold',
        // whiteSpace: "nowrap",
        maxWidth: '100%',

        overflow: "hidden",
        textOverflow: "ellipsis",
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
      },


    }}
    {...props}
  >
    {props.children}
  </DatagridConfigurable>
};

export default CommonDataGrid;