import React, { useState, useEffect } from "react";
import {
  DefaultEditorOptions,
  RichTextInput,
  RichTextInputToolbar,
  LevelSelect,
  FormatButtons,
  AlignmentButtons,
  ListButtons,
  LinkButtons,
  QuoteButtons,
  ClearButtons,
  useTiptapEditor,
} from "ra-input-rich-text";
import { SelectInput, MenuItem } from "react-admin";
import { Select } from "@mui/material";
import { FontSize } from "../RichTextEditor/RichTextExtentionFontSize";
import "./RichTextEditor.css";

export const MyRichTextInputToolbar = ({ size, ...props }) => {
  return (
    <RichTextInputToolbar {...props}>
      <FontSizeControls />
      <LevelSelect size={size} />
      <FormatButtons size={size} />
      <AlignmentButtons size={size} />
      <ListButtons size={size} />
      <LinkButtons size={size} />
      <QuoteButtons size={size} />
      <ClearButtons size={size} />
    </RichTextInputToolbar>
  );
};

export const CustomRichTextEditor = ({ size, ...props }) => (
  <RichTextInput
    editorOptions={MyEditorOptions}
    toolbar={<MyRichTextInputToolbar size={size} />}
    label="Body"
    source="body"
    {...props}
  />
);

export const MyEditorOptions = {
  ...DefaultEditorOptions,
  extensions: [...DefaultEditorOptions.extensions, FontSize],
};

export const FontSizeControls = ({ ...props }) => {
  const editor = useTiptapEditor();
  const [currentFontSize, setCurrentFontSize] = useState(null);

  useEffect(() => {
    // Update the current font size when the editor changes
    if (editor) {
      const activeMarks = editor.getAttributes("textStyle");
      setCurrentFontSize(activeMarks ? activeMarks.fontSize || null : null);
    }
  }, [editor]);

  const handleFontSizeChange = (event) => {
    const newFontSize = event.target.value;
    if (newFontSize !== null) {
      editor.chain().focus().setFontSize(newFontSize).run();
    } else {
      editor.chain().focus().unsetFontSize().run();
    }
  };

  // const activeMarks = editor.getAttributes("textStyle");
  // const currentFontSize = activeMarks ? activeMarks.fontSize || null : null;

  return (
    <SelectInput
      label="Font Size"
      source="fontSize"
      value={currentFontSize != null ? currentFontSize.toString() : ""}
      onChange={handleFontSizeChange}
      choices={[
        { id: "8", name: "8" },
        { id: "9", name: "9" },
        { id: "10", name: "10" },
        { id: "11", name: "11" },
        { id: "12", name: "12" },
        { id: "14", name: "14" },
        { id: "16", name: "16" },
        { id: "18", name: "18" },
        { id: "20", name: "20" },
        { id: "22", name: "22" },
        { id: "24", name: "24" },
        { id: "26", name: "26" },
        { id: "28", name: "28" },
        { id: "36", name: "36" },
        { id: "48", name: "48" },
        { id: "72", name: "72" },
        // Add more font size options as needed
      ]}
      emptyValue={""}
      {...props}
    />
  );
};
