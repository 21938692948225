import { ReferenceField, TextField, usePermissions, useRecordContext } from "react-admin";
import { Chip } from "@mui/material";

export function PreparerField(props) {
    const {isLoading, permissions } = usePermissions();
    const record = useRecordContext();
    if (isLoading || !record) return null;
    if (record.createdBy._id == permissions._id)
        return <Chip
        label={record.createdBy.name}
        size="small"
        color="primary" />
    return <TextField style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
    }} label="Preparer" source="createdBy.name" />
    // return <ReferenceField label="Preparer" reference="user_names" source="createdBy" link={false}  />
}

PreparerField.defaultProps = { label: 'Preparer' };

{/* <FunctionField
    sortBy="preparer"
    label="Preparer"
    render={(record) =>
        // record.team?.name
        // ? `${record.preparer.name}, ${record.team?.name}`
        record.preparer.name
    }
/> */}