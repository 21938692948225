import { stringify } from "query-string";
import { HttpError, fetchUtils } from "react-admin";
import casesProviderGenerator from "./custom/casesProviderGenerator";
import projectProviderGenerator from "./custom/projectProviderGenerator";
import commentsProviderGenerator from "./custom/commentsProviderGenerator";

/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 *
 * @example
 *
 * getList          => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * getOne           => GET http://my.api.url/posts/123
 * getManyReference => GET http://my.api.url/posts?author_id=345
 * getMany          => GET http://my.api.url/posts?id=123&id=456&id=789
 * create           => POST http://my.api.url/posts/123
 * update           => PUT http://my.api.url/posts/123
 * updateMany       => PUT http://my.api.url/posts/123, PUT http://my.api.url/posts/456, PUT http://my.api.url/posts/789
 * delete           => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 * import jsonServerProvider from 'ra-data-json-server';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={jsonServerProvider('http://jsonplaceholder.typicode.com')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */

function isFileInput(value) {
  return !!(value && typeof value === 'object' && value['rawFile']);
}

function hasFileInput(dataObject) {
  for (const key in dataObject) {
    if (isFileInput(dataObject[key]))
      return true;
    else if (Array.isArray(dataObject[key]) && dataObject[key].some(isFileInput))
      return true;
  }
  return false;
}

function isJson(str) {
  try {
    JSON.parse(JSON.stringify(str));
  } catch (e) {
    return false;
  }
  return true;
}

function stringifyIfObject(value) {
  if (value != null && typeof value === 'object') {
    return JSON.stringify(value);
  } else {
    return value;
  }
}

function toFormData(dataObject) {
  const formData = new FormData();
  for (const key in dataObject) {
    if (isFileInput(dataObject[key])) {
      formData.append(key, dataObject[key].rawFile);
    } else if (Array.isArray(dataObject[key])) {
      dataObject[key].forEach((value, index) => {
        if (isFileInput(value)) {
          formData.append(key, value.rawFile);
        } else if (value !== undefined) { // remove properties with undefined value (eg from <SelectInput>) 
          formData.append(key, stringifyIfObject(value));
        }
      });
    } else if (dataObject[key] !== undefined) { // remove properties with undefined value (eg from <SelectInput>) 
      formData.append(key, stringifyIfObject(dataObject[key]));
    }
  }
  return formData;
}

function toRequestBody(dataObject) {
  if (dataObject instanceof FormData) return dataObject;
  if (hasFileInput(dataObject)) return toFormData(dataObject);
  return JSON.stringify(dataObject); // properties with undefined value (eg from <SelectInput>) are auto-removed by JSON.stringify
}

function parseFilterOperator(filter) {
  console.log('filter', filter)
  const operators = { '_gte': '$gte', '_lte': '$lte', '_neq': '$ne', '_gt': '$gt', '_lt': '$lt' }; // missing $in/$nin: [...]
  const filters = Object.keys(filter).map(key => {
    const operator = operators[key.slice(-4)] || operators[key.slice(-3)]; // check 4 char suffix first, then 3 char suffix
    return operator
      ? { [key.slice(0, -operator.length)]: { [operator]: filter[key] } } // {age_gte: 18} => {age: { $gte: 18 }}
      : { [key]: filter[key] };
  });
  return filters;
}
// const filter = fetchUtils.flattenObject(params.filter); // { A: { B: 1 } } => { 'A.B': 1 }
// parseFilterOperator({f1_gt: 1, f2_lte: 2}) => [{field: 'f1', operator: '>', value: 1}, {field: 'f2', operator: '<=', value: 2}]

function coreProviderGenerator(apiUrl = "", httpClient = fetchUtils.fetchJson) {
  return {
    getList: (resource, params) => {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const filter = fetchUtils.flattenObject(params.filter);
      const query = {
        ...filter,
        //filter: parseFilterOperator(filter), // todo server side
        _sort: field,
        _order: order,
        _start: (page - 1) * perPage,
        _end: page * perPage,
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      return httpClient(url).then(({ headers, json }) => {
        if (!headers.has("x-total-count")) {
          throw new Error(
            "The X-Total-Count response HTTP header is missing. It's required for pagination in jsonServer Data Provider. If CORS, do declare X-Total-Count in the Access-Control-Expose-Headers header."
          );
        }
        return {
          data: json.doc.map((x) => ({ id: x._id, ...x })),
          total: parseInt(headers.get("x-total-count").split("/").pop(), 10),
        };
      });
    },

    getOne: (resource, params) => {
      return httpClient(`${apiUrl}/${resource}/${params.id}`).then(
        ({ json }) => {
          return {
            data: { id: json.doc._id, ...json.doc },
          };
        }
      );
    },

    getMany: (resource, params) => {
      const query = {
        ...fetchUtils.flattenObject(params.filter),
        _id: params.ids,
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      return httpClient(url).then(({ json }) => ({
        data: json.doc.map((x) => ({ id: x._id, ...x })),
      }));
    },

    getManyReference: (resource, params) => {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        ...fetchUtils.flattenObject(params.filter),
        [params.target]: params.id,
        _sort: field?.replace(/^id$/, "_id"),
        _order: order,
        _start: (page - 1) * perPage,
        _end: page * perPage,
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;

      return httpClient(url).then(({ headers, json }) => {
        if (!headers.has("x-total-count")) {
          throw new Error(
            "The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?"
          );
        }
        return {
          data: json.doc.map((x) => ({ id: x._id, ...x })),
          total: parseInt(headers.get("x-total-count").split("/").pop(), 10),
        };
      });
    },

    update: (resource, params) => {
      const body = toRequestBody(params.data);
      return httpClient(`${apiUrl}/${resource}/${params.id}`,
        { method: "PUT", body, }).then(({ json }) => ({ data: { id: json.doc._id, ...json.doc } }));
    },

    // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    updateMany: (resource, params) => {
      const body = toRequestBody(params.data);
      Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, { method: "PUT", body })
        )
      ).then((responses) => ({
        data: responses.map(({ json }) => json.doc._id),
      }));
    },

    create: (resource, params) => {
      const body = toRequestBody(params.data);
      return httpClient(`${apiUrl}/${resource}`,
        { method: "POST", body }).then(({ json }) => ({ data: { id: json.doc._id, ...json.doc } }));
    },

    delete: (resource, params) =>
      httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: "DELETE",
      }).then(({ json }) => ({ data: { id: json.doc._id, ...json.doc } })),

    // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>
      Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "DELETE",
          })
        )
      ).then((responses) => ({
        data: responses.map(({ json }) => json.doc._id),
      })),
  };
};

/**
 * @param
 * apiUrl - The root api url (normally blank)
 * httpClient - The http client react admin provides to make requests
 *
 * This function compiles custom provider generators and make 1 single super provider to
 * be used by react-admin. You may add your own providers here if needed
 *
 */
const providerObjectFactory = (apiUrl, httpClient) => {
  const coreProvider = coreProviderGenerator(apiUrl, httpClient);
  const casesProviderOverload = casesProviderGenerator(apiUrl, httpClient);
  const projectProviderOverload = projectProviderGenerator(apiUrl, httpClient);
  const commentsProviderOverload = commentsProviderGenerator(apiUrl, httpClient);

  const combinedProviders = {
    ...coreProvider,
    ...casesProviderOverload,
    ...projectProviderOverload,
    ...commentsProviderOverload,
  };

  return combinedProviders;
};

export default function dataProvider(apiUrl) {
  // function httpClient(url, options = {}) {
  //   const token = localStorage.getItem('token');
  //   if (!token) {
  //     return Promise.reject(new HttpError("Please login", 401, null));
  //   }
  //   options.credentials = 'omit';
  //   options.user = { authenticated: true, token: 'Bearer ' + token };
  //   return fetchUtils.fetchJson(url, options);
  // }
  return providerObjectFactory(apiUrl, fetchUtils.fetchJson);
};