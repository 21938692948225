import {
  List,
  Datagrid,
  TextField,
  EmailField,
  BooleanField,
  ArrayField,
  ChipField,
  SingleFieldList,
  SearchInput,
  ReferenceArrayField,
  useRecordContext,
  EditButton,
  TopToolbar,
  FilterButton,
  CreateButton,
  ExportButton,
  CloneButton,
  required,
  DateField,
  NumberField,
  ShowButton,
  WithRecord,
  FileField,
  FileInput,
  RecordContextProvider,
  ReferenceField,
  SelectInput,
  AutocompleteInput,
  ReferenceInput,
  SelectColumnsButton,
  defaultExporter,
  useListController,
} from "react-admin";
import ContentAdd from "@mui/icons-material/Add";
import AvatarField from "../components/AvatarField";
import CommonDataGrid from "../components/CommonDataGrid";
import { customExporter } from "../utils/customExporter";

const ListActions = ({ project }) => {
  return (
    <TopToolbar style={{ minHeight: 20 }}>
      <SelectColumnsButton />
      <FilterButton />
      <ExportButton />
      <CloneButton
        variant="contained"
        // variant="outlined"
        label="Create"
        resource="drawings"
        record={{
          project: project.id,
          project_id: project.id,
          project_name: project.name,
        }}
        icon={<ContentAdd />}
        sx={{ marginLeft: 2 }}
      />
      {/* <CreateButton /> */}
    </TopToolbar>
  );
};

const filters = (projectDropdown) => {
  return [
    <AutocompleteInput source="preparer" alwaysOn />,
    <AutocompleteInput source="drawing_status" alwaysOn />,
  ];
};

// Define constants for custom export
// Header name should follow according to the grid title
const headerTemplate = "Type,Revision,Uploader,Status,Open Cases,Total Cases,Updated At";

// Parse in the record arrangement as the display
// rowTemplate for CSV
const rowTemplate = (record) => {
  return `${record.drawingType.name},${record.version},${record.createdByName},${record.drawingStatus.name},${record.openCases || ''},${record.totalCases || ''},${record.updatedAt}`;
};

export const DrawingList = (props) => {
  const project_record = useRecordContext();
  if (!project_record) return null;
  // const projectDrowdown = useProjectDropdown(project_record);
  const gfs = (id) => `/uploads/render/${id}`;
  const filters = [
    <ReferenceInput
      source="drawingType"
      reference="drawing_types"
      filter={{ project_id: project_record.id }}
      alwaysOn
    >
      <SelectInput
        optionText="name"
        emptyText="Show All"
        label="Drawing Type"
      />
    </ReferenceInput>,
    <SearchInput source="version" placeholder="Revision" alwaysOn />,
    <ReferenceInput
    source="createdBy"
    reference="users"
    filter={{ project_id: project_record?.id }}
    sort={{ field: "name", order: "ASC" }}
    alwaysOn
    >
      <SelectInput optionValue="id" optionText="name" emptyText="Show All" label="Uploader"/>
    </ReferenceInput>,
    <ReferenceInput
      source="drawingStatus"
      reference="drawing_statuses"
      filter={{ project_id: project_record.id }}
    >
      <SelectInput
        optionText="name"
        emptyText="Show All"
        label="Drawing Status"
      />
    </ReferenceInput>,
  ];

  return (
    <List
      empty={false}
      title=" "
      disableSyncWithLocation
      resource="drawings"
      actions={<ListActions project={project_record} />}
      {...props}
      filter={{ project_id: project_record.id }}
      filters={filters}
      // filterDefaultValues={{ status: defaultfilterCaseStatus}}
      sort={{ field: "updatedAt", order: "DESC" }}
      exporter={(records, fetchRelatedRecords) =>
        customExporter(records, fetchRelatedRecords, headerTemplate, rowTemplate, "drawings")
      }
    >
      <CommonDataGrid>
        {/* <ReferenceField
          source="drawing_type"
          reference="drawing_types"
          label="Drawing Type"
        >
          <TextField source="name" />
        </ReferenceField> */}
        {/* <TextField source="drawing_type.name" /> */}
        <TextField source="drawingType.name" label="Type" />
        <TextField source="version" label="Revision" />
        {/* <WithRecord
          render={(x) => (
            <RecordContextProvider
              value={{
                ...x,
                url: gfs(x.file_id),
              }}
            >
              <FileField source="url" title="drawing_status" />
              <form method="post" action={gfs(x.file_id)} target="_blank">
                <button type="submit">View</button>
              </form>
            </RecordContextProvider>
          )}
        /> */}

        <ReferenceField source="createdBy" reference="users" label="Uploader">
          <TextField
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
            source="name"
          />
        </ReferenceField>
        <TextField source="drawingStatus.name" label="Status" />
        <NumberField source="openCases" label="Open Cases" />
        <NumberField source="totalCases" label="Total Cases" />
        <DateField source="updatedAt" />
      </CommonDataGrid>
    </List>
  );
};
