import {
  List,
  Datagrid,
  TextField,
  EmailField,
  BooleanField,
  ArrayField,
  ChipField,
  SingleFieldList,
  SearchInput,
  ReferenceArrayField,
  useRecordContext,
  EditButton,
  TopToolbar,
  FilterButton,
  CreateButton,
  ExportButton,
  CloneButton,
  required,
  DateField,
  NumberField,
  ShowButton,
  WithRecord,
  FileField,
  FileInput,
  RecordContextProvider,
  ReferenceField,
  SelectInput,
  AutocompleteInput,
  ReferenceInput,
  SelectColumnsButton,
  defaultExporter,
  useListController,
} from "react-admin";
import ContentAdd from "@mui/icons-material/Add";
import AvatarField from "../components/AvatarField";
import CommonDataGrid from "../components/CommonDataGrid";
import { customExporter } from "../utils/customExporter";

const ListActions = ({ project }) => {
  return (
    <TopToolbar style={{ minHeight: 20 }}>
      <SelectColumnsButton />
      {/* <FilterButton /> */}
      <ExportButton />
      <CloneButton
        variant="contained"
        // variant="outlined"
        label="Create"
        resource="minutes"
        record={{
          project: project.id,
          project_id: project.id,
          project_name: project.name,
        }}
        icon={<ContentAdd />}
        sx={{ marginLeft: 2 }}
      />
      {/* <CreateButton /> */}
    </TopToolbar>
  );
};

const filters = (projectDropdown) => {
  return [
    // <ReferenceInput source="drawing_type" reference="drawing_types">
    //   <AutocompleteInput
    //     optionText={(choice) =>
    //       choice?.id ? `${choice.first_name} ${choice.last_name}` : ""
    //     }
    //   />
    // </ReferenceInput>,
    // <AutocompleteInput source="drawing_type" alwaysOn />,
    <AutocompleteInput source="preparer" alwaysOn />,
    // <AutocompleteInput source="drawing_status" alwaysOn />,
    // <SelectInput
    //   source="preparer"
    //   label="Uploader"
    //   // choices={projectStatusEnum}
    //   alwaysOn
    // />,
  ];
};

// Define constants for custom export
// Header name should follow according to the grid title
const headerTemplate = "Title,Uploader,Status,Open Cases,Total Cases,Updated At";

// Parse in the record arrangement as the display
// rowTemplate for CSV
const rowTemplate = (record) => {
  return `${record.title},${record.createdByName},${record.minuteStatus.name},${record.openCases || ''},${record.totalCases || ''},${record.updatedAt}`;
};

export const MinuteList = (props) => {
  const project_record = useRecordContext();
  if (!project_record) return null;
  // const projectDrowdown = useProjectDropdown(project_record);
  const gfs = (id) => `/uploads/render/${id}`;

  const filters = [
    <SearchInput source="title" placeholder="Title" alwaysOn />,
    // <SelectInput source="status" choices={caseStatusEnum} emptyText="Show All" alwaysOn />,
    // showProject? null : <ReferenceInput source="case_type_id" reference="case_types" filter={{project_id: project?.id}} />,
    <ReferenceInput
    source="createdBy"
    reference="users"
    filter={{ project_id: project_record?.id }}
    sort={{ field: "name", order: "ASC" }}
    alwaysOn
    >
      <SelectInput optionValue="id" optionText="name" emptyText="Show All" label="Uploader"/>
    </ReferenceInput>,
    <ReferenceInput
    source="minuteStatus"
    reference="minute_statuses"
    filter={{ project_id: project_record?.id }}
    sort={{ field: "name", order: "ASC" }}
    alwaysOn
    >
      <SelectInput optionValue="id" optionText="name" emptyText="Show All" label="Status"/>
    </ReferenceInput>,
  ];
  return (
    <List
      empty={false}
      title=" "
      disableSyncWithLocation
      resource="minutes"
      filters={filters}
      actions={<ListActions project={project_record} />}
      {...props}
      filter={{ project_id: project_record.id }}
      // filters={filters()}
      // filterDefaultValues={{ status: defaultfilterCaseStatus}}
      sort={{ field: "updatedAt", order: "DESC" }}
      exporter={(records, fetchRelatedRecords) =>
        customExporter(records, fetchRelatedRecords, headerTemplate, rowTemplate, "minutes")
      }
    >
      <CommonDataGrid>
        <TextField source="title" />
        <ReferenceField source="createdBy" reference="users" label="Uploader">
          <TextField
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
            source="name"
          />
        </ReferenceField>
        <TextField source="minuteStatus.name" label="Status" />
        <NumberField source="openCases" label="Open Cases" />
        <NumberField source="totalCases" label="Total Cases" />
        <DateField source="updatedAt" />
      </CommonDataGrid>
    </List>
  );
};
