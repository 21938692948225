import { RichTextInput } from "ra-input-rich-text";
import {
  Edit,
  SimpleForm,
  ImageInput,
  ImageField,
  NumberField,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  TabbedForm,
  FormTab,
  BooleanInput,
  PasswordInput,
  required,
  TextField,
  FileField,
  TextInput,
  TopToolbar,
  SimpleShowLayout,
  WithRecord,
  ListButton,
  ReferenceField,
  ReferenceInput,
  useRecordContext,
  Labeled,
  ShowButton,
  FunctionField,
} from "react-admin";
import { CreateTag } from "../components/CreateTag";
import { CustomRichTextEditor } from "../erpd";

const Title = ({ record }) => {
  if (!record) return null;
  return <span>User: {record.name}</span>;
};

const EditLayout = (props) => {
  const record = useRecordContext(props);
  return (
    <>
      {" "}
      <SimpleShowLayout>
        <ReferenceField source="project_id" reference="projects" link="show">
          <WithRecord render={(record) => record.name} />
        </ReferenceField>
        <FunctionField
          label="File"
          render={(record) => (
            <a
              title={`${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}uploads/render/${record["file_id"]}`}
            >
              {record.upload.originalname} ({record.upload.size}B)
            </a>
          )}
        />
      </SimpleShowLayout>
      <SimpleForm>
        <TextInput source="title" label="Title" />

        <ReferenceInput
          label="Minute Status"
          source="minuteStatus._id"
          reference="minute_statuses"
          filter={{ project_id: record.project_id }}
        >
          <SelectInput
            optionValue="id"
            optionText="name"
            validate={required()}
            create={<CreateTag resource="minute_statuses" />}
            createLabel="(Create...)"
          ></SelectInput>
        </ReferenceInput>

        <CustomRichTextEditor source="remarks" fullWidth />
      </SimpleForm>
    </>
  );
};

export const MinuteEdit = (props) => {
  function makeChoices(array) {
    return array.map((x) => ({ id: x.name, name: x.name }));
  }

  return (
    <Edit
      {...props}
      redirect="show"
      title={
        <WithRecord render={(x) => <span>Meeting Minute: {x.title}</span>} />
      }
      actions={
        <TopToolbar style={{ minHeight: 20 }}>
          <WithRecord
            render={(record) => (
              <ListButton to={`/projects/${record.project_id}/show/minutes`} />
            )}
          />
          <ShowButton />
        </TopToolbar>
      }
    >
      <EditLayout />
    </Edit>
  );
};
