import { Avatar, Button, Link, Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: theme.spacing(8),
}));

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(1),
}));

export const StyledForm = styled("form")(({ theme }) => ({
  margin: theme.spacing(1),
  width: "100%",
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

export function Copyright() {
  return (
    <Box mt={8}>
      {/* <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="#">
          ERPD
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography> */}
    </Box>
  );
}
