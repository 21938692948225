import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  useRedirect,
  useDataProvider,
  Edit,
} from "react-admin";
import { Dialog } from "@mui/material";

const validateRequired = required();

export const CaseTypeEdit = ({ resource, record, project_id, handleClose }) => {
  const redirect = useRedirect();

  //   const handleClose = () => {
  //     redirect("/projects/" + project_id);
  //   };

  const onSuccess = (deal) => {
    // redirect("/projects/" + project_id);
    handleClose();
  };

  return (
    <Dialog open={!!record} onClose={handleClose}>
      {record && (
        <Edit
          id={record.id}
          resource={resource}
          redirect={false}
          mutationMode="pessimistic"
          mutationOptions={{ onSuccess }}
          sx={{ width: 500, "& .RaCreate-main": { mt: 0 } }}
        >
          <SimpleForm>
            <TextInput source="name" fullWidth validate={validateRequired} />
          </SimpleForm>
        </Edit>
      )}
    </Dialog>
  );
};
