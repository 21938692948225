import { Grid } from "@mui/material";
import {
  Link,
  usePermissions,
  List,
  useListContext,
  SearchInput,
  TopToolbar,
  FilterButton,
  ExportButton,
  CreateButton,
  useRedirect,
  ResourceContextProvider,
  Empty,
} from "react-admin";
// import { createHashHistory } from "history";
import OverallCases from "../components/OverallElement";

//TODO: After finish, place all style to style file
//TODO: Add "add image section"

const CaseNumber = ({ color, value, ...props }) => {
  return (
    <Grid
      item
      xs={2}
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
      }}
      {...props}
    >
      <div
        style={{
          height: 9,
          width: 9,
          borderRadius: 9,
          backgroundColor: color,
          marginRight: 10,
        }}
      />
      <h1
        style={{
          fontSize: 16,
          margin: 0,
          fontWeight: "500",
          color: "#7D7D7D",
        }}
      >
        {value}
      </h1>
    </Grid>
  );
};

// function handleCaseNavigation(event) {
//   const history = createHashHistory();
//   const project_id = event.target.getAttribute('data-project_id');
//   const from_to = event.target.getAttribute('data-from_to');
//   const status = event.target.getAttribute('data-status');
//   const filter = {};
//   if (from_to) filter.from_to = from_to;
//   if (status) filter.status = status;
//   console.log('event', event.target, status);
//   const uri = `/projects/${project_id}/cases?filter=` + encodeURIComponent(JSON.stringify(filter));
//   console.log('clicked', uri);
//   history.push(uri);
// }

const CaseElement = ({
  title,
  overdueNumber,
  openNumber,
  completedNumber,
  ongoingNumber,
  showBorderBottom,
  project_id,
}) => {
  const redirect = useRedirect();
  const clickHandler = (event) => {
    let elem = event.target;
    while (elem && !elem.getAttribute("data-project_id")) {
      elem = elem.parentElement;
    }
    if (elem) {
      const project_id = elem.getAttribute("data-project_id");
      const fromto = elem.getAttribute("data-fromto");
      const status = elem.getAttribute("data-status");
      if (fromto == 'Ongoing' || status == 'Ongoing') {
        const uri = `/projects/${project_id}/show?filter=` + encodeURIComponent(JSON.stringify({ongoing: true}));
        redirect(uri);
        return;
      }
      const filter = { fromto };
      if (status) filter.status = status;
      const uri =
        `/projects/${project_id}/show?filter=` +
        encodeURIComponent(JSON.stringify(filter));
      redirect(uri);
    }
  };

  return (
    <Grid
      container
      style={{
        height: 50,
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        borderTopWidth: 0,
        borderBottomWidth: showBorderBottom ? 1 : 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderColor: "#EAEAEA",
        borderStyle: "solid",
      }}
    >
      <Grid item xs={6}>
        <h1
          style={{
            fontSize: 16,
            margin: 0,
            fontWeight: "500",
            color: "#7D7D7D",
            cursor: "pointer",
          }}
          data-project_id={project_id}
          data-fromto={title}
          onClick={clickHandler}
        >
          {title}
        </h1>
      </Grid>
      { title != "Ongoing" &&
      <>
      <CaseNumber
        data-project_id={project_id}
        data-fromto={title}
        data-status="Overdued"
        color={"#EB4040"}
        value={overdueNumber || 0}
        onClick={clickHandler}
      />
      <CaseNumber
        data-project_id={project_id}
        data-fromto={title}
        data-status="Open"
        color={"#2972FF"}
        value={openNumber || 0}
        onClick={clickHandler}
      />
      <CaseNumber
        data-project_id={project_id}
        data-fromto={title}
        data-status="Completed"
        color={"#24CB20"}
        value={completedNumber || 0}
        onClick={clickHandler}
      />
      </>
      }
      { title == "Ongoing" &&
      <CaseNumber
        data-project_id={project_id}
        data-fromto={title}
        data-status="Ongoing"
        color={"#fff"}
        value={(ongoingNumber || 0)}
        onClick={clickHandler}
      />
      }
    </Grid>
  );
};

const ProjectCard = ({ data }) => {
  const redirect = useRedirect();
  const result = [
    { title: "By Me", summary: data.summary[0] },
    { title: "To Me", summary: data.summary[1] },
    { title: "To My Department", summary: data.summary[2] },
    { title: "To Everyone", summary: data.summary[3] },
    { title: "Other", summary: data.summary[4] }, //not related to me, for admin
    { title: "Ongoing", summary: data.summary[5] }, //not related to me, for admin
  ];

  const defaultImage = require("../assets/dashboard/default.png");
  return (
    <Grid item xs={12} sm={6} lg={4}>
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: 15,
        }}
      >
        {/* image area */}
        <div
          style={{
            height: 160,
            backgroundColor: "#B7B7B7",
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
            backgroundSize: "cover",
            backgroundImage: 'url("' + (data.imageSrc ?? defaultImage) + '")',
            cursor: "pointer",
          }}
          // onClick={() => redirect('show', 'projects', data.id)}
          onClick={() => redirect(`/projects/${data.id}/show?filter={}`)}
        />

        <div
          style={{
            paddingTop: 17,
            paddingBottom: 17,
            paddingLeft: 27,
            paddingRight: 27,
          }}
        >
          <h1
            style={{
              fontWeight: "bold",
              fontSize: 20,
              marginTop: 0,
              marginBottom: 23,
            }}
          >
            {data.name}
          </h1>

          <h1
            style={{
              fontSize: 18,
              marginTop: 0,
              fontWeight: "500",
              marginBottom: 10,
            }}
          >
            Cases Raised
          </h1>

          {result.map((x, i) => (
            <CaseElement
              key={i + ""}
              title={x.title}
              overdueNumber={x.summary["Overdued"]}
              openNumber={x.summary["Open"]}
              completedNumber={x.summary["Completed"]}
              ongoingNumber={x.summary["Ongoing"]}
              showBorderBottom={i != result.length - 1}
              project_id={data.id}
            />
          ))}
        </div>
      </div>
    </Grid>
  );
};

const ProjectList = () => {
  const { data, isLoading } = useListContext();
  const { permissions } = usePermissions();
  if (isLoading) return null;

  const getOverallStatistic = (data) => {
    const result = { overdue: 0, open: 0, completed: 0, ongoing: 0};
    // let ongoing = 0; // ongoing is not a real status. it could be open or overdued AND user is an observer
    
    if (!!data) {
      for (const key in data) {
        const x = data[key];

        for (const key in x.summary) {
          const summary = x.summary[key];
          // exclude other and ongoing
          if (key != 4 && key != 5) {
            result.overdue += summary["Overdued"] ? +summary["Overdued"] : 0;
            result.open += summary["Open"] ? +summary["Open"] : 0;
          }
          result.completed += summary["Completed"] ? +summary["Completed"] : 0;
          result.ongoing += summary["Ongoing"] ? +summary["Ongoing"] : 0;
        }
      }
    }

    return result;
  };

  const result = getOverallStatistic(data);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 23,
        }}
      >
        <h1
          style={{
            fontWeight: "bold",
            fontSize: 30,
            margin: 0,
            alignItems: "end",
          }}
        >
          Overall Statistics
        </h1>
        {permissions?.canCreateProject() && (
          <CreateButton
            resource="projects"
            variant="contained"
            sx={{ marginLeft: 2 }}
          />
        )}
      </div>

      <OverallCases result={result} />

      <h1
        style={{
          fontWeight: "bold",
          fontSize: 30,
          marginTop: 0,
          // marginBottom: 23,
        }}
      >
        Projects
      </h1>

      <Grid container spacing={2} style={{ marginBottom: 65 }}>
        {data?.map((x, i) => (
          <ProjectCard key={i + ""} data={x} />
        ))}
      </Grid>
    </>
  );
};

export default () => {
  const { isLoading, permissions } = usePermissions();

  // const ListActions = () => {
  //   return (
  //     // <div style={{ height: 0 }}>

  //     <TopToolbar>
  //       {/* <FilterButton /> */}
  //       {/* <ExportButton />
  //       <CreateButton variant="contained" sx={{ marginLeft: 2 }} /> */}
  //     </TopToolbar>
  //     // </div >
  //   );
  // };

  // const filters = [
  //   // <TextInput label="Search" source="a" alwaysOn />,
  //   <SearchInput
  //     source="q"
  //     // placeholder="Search name"
  //     // validate={regex(/^[.\w@ ]+$/, "Must be alphanumeric")}
  //     alwaysOn
  //   />,
  // ];

  return (
    //margin top remove the weird space when action button present
    <div style={{ marginTop: -0 }}>
      <List
        resource="myprojects"
        component={"div"}
        //temporaly disable action button since there isn't any in the design
        actions={false}
        bulkActionButtons={false}
        // title="Welcome to eRDP"
        // disableSyncWithLocation
        // filters={filters}
        empty={
          <ResourceContextProvider value="projects">
            <Empty hasCreate={permissions?.canCreateProject()} />
          </ResourceContextProvider>
        }
        pagination={false}
        filter={{ status: "Open" }}
        queryOptions={{ queryKey: "dashboard" }}
      >
        <ProjectList />
      </List>
    </div>
  );
};
