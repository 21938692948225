
// Custom Exporter function
export const customExporter = (records, fetchRelatedRecords, headerTemplate, rowTemplate, resource) => {
    // Create a header row with field names
    const headerRow = `${headerTemplate}\n`;
    debugger;
    // For example, you can create a CSV string from the records
    const csv = records.reduce((acc, record) => {
      // Customize this logic based on your data structure
      const row = `${rowTemplate(record)}\n`;
      return `${acc}${row}`;
    }, headerRow);
  
    // Trigger a download of the CSV file
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${resource}.csv`;
    link.click();
};
// End Custom Exporter

// extra code in case we need XLSX version
// Import only the required functions from 'xlsx'
// import { utils as XLSXUtils, writeFile as writeXLSXFile } from 'xlsx';

// export const customExporter = (records, fetchRelatedRecords, headerTemplate, rowTemplate, resource) => {
//     // Create a header row with field names
//     const headerRow = headerTemplate.split(','); // Split header into an array
//     const headerRows = [headerRow];

//     // For example, you can create an XLSX worksheet from the records
//     const rows = records.map(record => rowTemplate(record));

//     // Combine header rows and data rows
//     const dataRows = [...headerRows, ...rows];

//     // Create a worksheet
//     const ws = XLSXUtils.aoa_to_sheet(dataRows);

//     // Adjust column widths based on the length of each item in the header row
//     const colWidths = headerRow.reduce((acc, col, i) => {
//         acc[i] = { width: { wch: Math.max(...dataRows.map(row => row[i].length)) } };
//         return acc;
//     }, {});
//     ws['!cols'] = colWidths;

//     // Create a workbook
//     const wb = XLSXUtils.book_new();
//     XLSXUtils.book_append_sheet(wb, ws, 'Sheet 1');

//     // Trigger a download of the XLSX file
//     writeXLSXFile(wb, `${resource}.xlsx`);
// };