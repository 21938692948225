import { useParams } from "react-router";
import {
  Edit,
  TextInput,
  BooleanInput,
  required,
  TopToolbar,
  ListButton,
  Datagrid,
  ReferenceField,
  TextField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  BooleanField,
  List,
  WithRecord,
  ImageInput,
  ImageField,
  useRefresh,
} from "react-admin";
import { SimpleForm } from "../erpd";
import { UserTitle } from "./UserTtile";

const Actions = () => (
  <TopToolbar style={{ minHeight: 20 }}>
    <ListButton />
  </TopToolbar>
);

const MemberList = (props) => {
  return (
    <Datagrid bulkActionButtons={false} rowClick="edit">
      <ReferenceField source="project_id" reference="projects" link="show">
        <TextField source="name" label="Project" />
      </ReferenceField>
      <TextField source="role" />
      <ReferenceArrayField source="groups" label="Team" reference="groups">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <BooleanField source="is_active" label="Active Member" />
      {/* <EditButton /> */}
    </Datagrid>
  );
};

export const UserEdit = (props) => {
  const { id } = useParams();
  const refresh = useRefresh();

  const onSuccess = () => {
    refresh();
    window.location.reload(false);
  };

  return (
    <>
      <Edit
        {...props}
        mutationMode="pessimistic"
        mutationOptions={{ onSuccess }}
        actions={<Actions />}
        title={<UserTitle />}
      >
        <SimpleForm>
          <TextInput
            type="email"
            source="email"
            // helperText="Unique email for login and notifications"
            readOnly
          />
          <WithRecord
            render={(record) => (
              <TextInput
                label="Display name"
                source="name"
                validate={required()}
                helperText={record.is_ad_login ? "" : "Unique display name"}
                readOnly={record.is_ad_login}
              />
            )}
          />
          <ImageInput
            source="avatar"
            label="Avatar"
            maxSize={5 * 1024 * 1024}
            accept="image/*"
          >
            <ImageField source="src" title="Avatar" />
          </ImageInput>
          <BooleanInput
            source="is_super_admin"
            label="Super Admin"
            helperText="Super admin can manage users"
          />
          <BooleanInput
            source="is_admin"
            label="Project Admin"
            helperText="Project admin can create new project"
          />
          <BooleanInput
            source="is_active"
            label="Active"
            helperText="Only active user can login"
          />
          {/* <DateInput source="__v" /> */}
        </SimpleForm>
      </Edit>
      <List
        hasCreate={false}
        // hasShow
        // hasEdit
        // hasList
        resource="members"
        filter={{ user_id: id }}
        title=" "
      >
        <MemberList />
      </List>
    </>
  );
};
