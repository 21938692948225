import {
  List,
  Datagrid,
  TextField,
  EmailField,
  SearchInput,
  regex,
  SelectInput,
  useRecordContext,
  ReferenceField,
  DatagridBody,
  FunctionField,
  TabbedShowLayout,
  defaultExporter,
  useListController,
  // Tab,
} from "react-admin";
import CommonDataGrid from "../components/CommonDataGrid";
import { Paper, Tabs, Tab } from "@mui/material";
import React from "react";
import { customExporter } from "../utils/customExporter";
import {
  AvatarField,
  BooleanField,
  QuickFilter,
  projectStatusEnum,
  defaultFilterProjectStatus,
  MyProjectRoleField,
  DateField,
} from "../erpd";

const filters = [
  <SelectInput
    emptyText="Show All"
    sx={{
      ".MuiFilledInput-root": {
        borderRadius: 2,
        border: "1px solid ",
      },
      ".MuiFilledInput-root::before": {
        borderWidth: 0,
      },
      ".MuiFilledInput-root:hover::before": {
        borderWidth: 0,
      },
      ".MuiFilledInput-root::after": {
        borderWidth: 0,
      },
      ".MuiFilledInput-root:hover::after": {
        borderWidth: 0,
      },
    }}
    source="status"
    choices={projectStatusEnum}
    alwaysOn
  />,
  <SearchInput
    source="q"
    placeholder="Search name"
    validate={regex(/^[.\w@ ]+$/, "Must be alphanumeric")}
    alwaysOn
  />,
];

// Define constants for custom export
// Header name should follow according to the grid title
const headerTemplate = "Name,Description,Status,Created By,Created At,Updated At";

// Parse in the record arrangement as the display
// rowTemplate for csv
const rowTemplate = (record) => {
  return `${record.name},${record.description},${record.status},${record.createdBy},${record.createdAt},${record.updatedAt}`;
};

//rowTemplate for xlsx
// Parse in the record arrangement as the display
// const rowTemplate = (record) => {
//   return [
//     record.name,
//     record.description,
//     record.status,
//     record.createdBy,
//     record.createdAt,
//     record.updatedAt,
//   ];
// };


export const ProjectList = (props) => {

  const { resource } = useListController(props);

  return (
    <Paper sx={{ padding: "1rem" }}>
      <List
        component={"div"}
        {...props}
        hasCreate={false}
        filters={filters}
        sort={{ field: "updatedAt", order: "DESC" }}
        exporter={(records, fetchRelatedRecords) =>
          customExporter(records, fetchRelatedRecords, headerTemplate, rowTemplate, resource)
        }
        // filterDefaultValues={{ status: defaultFilterProjectStatus, user: "*" }}
      >
        <CommonDataGrid>
          <TextField source="name" />
          <TextField source="description" />
          {/* <TextField source="created.by" />
        <DateField2 source="created.on" /> */}
          {/* <TextField source="status" /> */}
          {/* <MyProjectRoleField /> */}
          <FunctionField
            source="status"
            render={(record) => {
              return (
                <span
                  style={{
                    color:
                      record.status === "Open"
                        ? "#2972FF"
                        : record.status === "Completed"
                        ? "#1B9982"
                        : "#DE1515",
                  }}
                >
                  {record.status}
                </span>
              );
            }}
          />

          {/* <TextField label="Open Cases" /> */}
          <ReferenceField source="createdBy" reference="users">
            <TextField source="name" />
          </ReferenceField>
          <DateField source="createdAt" />
          <DateField source="updatedAt" />
        </CommonDataGrid>
      </List>
    </Paper>
  );
};
