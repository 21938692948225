import { useState } from "react";
import {
  Box,
  BoxProps,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import {
  useCreate,
  useCreateSuggestionContext,
  useRecordContext,
  useRefresh,
} from "react-admin";

export const CreateTag = ({ label = "New item", resource, project_id }) => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [value, setValue] = useState(filter || "");
  const [create] = useCreate();
  const record = useRecordContext();
  const refresh = useRefresh();
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setDisabled(true);

    create(
      resource,
      {
        data: {
          name: value,
          project_id: project_id || record.project_id,
        },
      },
      {
        onSuccess: ({ data }) => {
          setValue("");
          onCreate(data);
          refresh(); //workaround
          setDisabled(false);

        },
      }
    );
  };

  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            label={label}
            value={value}
            onChange={(event) => setValue(event.target.value)}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={disabled} type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
