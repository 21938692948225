import { useState, useEffect, useRef } from "react";
import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';
import {
    Button,
    Create,
    Edit,
    // DateInput,
    RadioButtonGroupInput,
    required,
    SaveButton,  
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    Toolbar,
    useNotify,
    usePermissions,
    useRecordContext,
    useRefresh,
    WithRecord,
} from "react-admin";
import { DateField2 } from "../erpd";

export function ReopenApproval({id, ...props})
{
  const [isRejected, setIsRejected] = useState(false)
  const caseRecord = useRecordContext(props);
  const notify = useNotify();
  const refresh = useRefresh();

  if (!id) return null;
  function ToolBarReopen() {
    return (
      <Toolbar>
          <SaveButton label="Submit" icon={false} />
          {/* <SaveButton label="Reject" sx={{ml:2}} /> */}
          {/* <Button onClick={hide} sx={{ml:2}}>Cancel</Button> */}
      </Toolbar>
    )
  }
  function onSuccess(data) {
      notify(`Case is successfully approved/rejected.`);
      refresh();
      // hide();
  }
  return (
    <Paper sx={{m:2}} elevation={3}>
      <Edit resource="reopen" id={id} redirect={false} mutationMode="pessimistic" mutationOptions={{ onSuccess }} queryOptions={{onError: (error)=> {notify('Error '+JSON.stringify(error))}}}>
        <SimpleShowLayout>
          <Typography variant="h6">Request for Re-opening Case</Typography>            
          <TextField source="commenter.name" label="Requestor" />
          <TextField label="Comment" source="message" />
          <DateField2 source="createdAt" />
          {/* <AssigneeInput source="assignee" label="Assignee" />
          <DateInput source="due_date" validate={[required()]} helperText={false} /> */}
          <WithRecord render={(record) => (record.approvalStatus === 'Approved' && <Typography>(This request has been approved)</Typography>)} />
          <WithRecord render={(record) => (record.approvalStatus === 'Rejected' && <Typography>(This request has been rejected)</Typography>)} />
          </SimpleShowLayout>
          <WithRecord render={(record) => (record.approvalStatus === 'Pending' && 

            <SimpleForm toolbar={<ToolBarReopen />}>
            <RadioButtonGroupInput label="Approval" source="approvalStatus" onChange={(event)=>setIsRejected(event.target.value==='Rejected')} choices={[
              { id: 'Approved', name: 'Approve' },
              { id: 'Rejected', name: 'Reject' },
            ]} helperText={false} autoFocus />
            <TextInput source="rejectionReason" required={isRejected} disabled={!isRejected} fullWidth multiline autoFocus helperText="* Required if the request is being rejected" />
        </SimpleForm>
        )} />
      </Edit>
    </Paper>
  );
}