import { Link, useParams } from "react-router-dom";
import {
  Show,
  Tab,
  TabbedShowLayout,
  EditButton,
  TopToolbar,
  usePermissions,
  useGetOne,
} from "react-admin";
import FolderIcon from "@mui/icons-material/Folder";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import PersonIcon from "@mui/icons-material/Person";
import { CaseList } from "../cases/CaseList";
import { NoteList } from "../notes/NoteList";
import { MemberList } from "../members/MemberList";
import { DrawingList } from "../drawings/DrawingList";
import { MinuteList } from "../minutes/MinuteList";
import OverallCases from "../components/OverallElement";
import { ProjectEdit } from "./ProjectEdit";
// import { MyProjectRoleField, caseStatusEnum } from "../erpd";
// import CommonDataGrid from "../components/CommonDataGrid";

// const Title = () => {
//   const record = useRecordContext();
//   const { permissions } = usePermissions();
//   if (!record || !permissions) return null;
//   return (
//     <span>
//       {record.name}
//       {permissions.isProjectAdmin(record._id) ? " (Admin)" : ""}
//     </span>
//   );
// };

//component root
export const ProjectShow = (props) => {
  const { id: projectId } = useParams();
  const { permissions } = usePermissions();

  const getOverallStatistic = (data) => {
    const result = { overdue: 0, open: 0, completed: 0, ongoing: 0 };

    if (!!data) {
      for (const key in data) {
        const summary = data[key];

        // console.log(summary)
        if (!!summary) {
          // exclude other and ongoing
          if (key != 4 && key != 5) {
            result.overdue += summary["Overdued"] ? +summary["Overdued"] : 0;
            result.open += summary["Open"] ? +summary["Open"] : 0;
          }
          result.completed += summary["Completed"] ? +summary["Completed"] : 0;
          result.ongoing += summary["Ongoing"] ? +summary["Ongoing"] : 0;
        }
      }
    }

    return result;
  };

  function Summary(props) {
    const { projectId } = props;
    const { data, isLoading, error } = useGetOne("count", { id: projectId });
    if (data) {
      return <OverallCases result={getOverallStatistic(data)} />;
    } else {
      return null;
    }
  }

  return (
    <Show
      component={"div"}
      // title={<Title />}
      sx={{
        boxShadow: "none",
        ".MuiPaper-root": {
          boxShadow: "none",
        },

        ".MuiTabs-root": {
          height: 40,
        },
        ".RaTabbedShowLayout-content": {
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        },
        ".MuiDivider-fullWidth": {
          borderWidth: 0,
        },
        ".MuiTabs-scroller": {
          backgroundColor: "#f5f5f5",
        },
        "a.MuiTab-root": {
          fontWeight: "550",
          color: "#7D7D7D",
          display: "flex",
          flexDirection: "row",
          height: 40,
          minHeight: 48,
        },
        "a.Mui-selected": {
          backgroundColor: "#fff",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          color: "#000000",
          height: 40,
          minHeight: 48,
        },

        ".MuiTabs-indicator": {
          display: "none",
        },
        ".MuiTab-root": {
          paddingLeft: 4,
          paddingRight: 4,
        },
      }}
      actions={false}
      // covered by OverallElement
      //   <TopToolbar style={{ position: "absolute", right: 0 }}>
      //     <div>
      //       <EditButton />
      //     </div>
      //   </TopToolbar>
    >
      <Summary projectId={projectId} />
      <TabbedShowLayout
        sx={{
          ".RaTabbedShowLayout-content": {
            backgroundColor: "#ffffff",
          },
        }}
      >
        <Tab
          label="Cases"
          icon={<FolderIcon style={{ marginRight: 15, marginBottom: 0 }} />}
        >
          <CaseList />
        </Tab>
        <Tab
          label="Notes"
          icon={<FolderIcon style={{ marginRight: 15, marginBottom: 0 }} />}
          path="notes"
        >
          <NoteList />
        </Tab>
        <Tab
          label="Drawings"
          icon={
            <FormatColorFillIcon style={{ marginRight: 15, marginBottom: 0 }} />
          }
          path="drawings"
        >
          <DrawingList />
        </Tab>
        <Tab
          label="Meeting Minutes"
          icon={
            <FactCheckIcon style={{ marginRight: 15, marginBottom: 0 }} />
          }
          path="minutes"
        >
          <MinuteList />
        </Tab>
        {permissions?.isProjectAdmin(projectId) && (
          <Tab
            label="Members"
            icon={<PersonIcon style={{ marginRight: 15, marginBottom: 0 }} />}
            path="members"
          >
            <MemberList />
          </Tab>
        )}
        {permissions?.isProjectAdmin(projectId) && (
          // <Tab disabled icon={} path="edit"></Tab>
          <EditButton className="MuiTab-root" style={{ position: "absolute", right: 10 }} />
        )}
      </TabbedShowLayout>
    </Show>
  );
};
