import {
  List,
  Datagrid,
  TextField,
  EmailField,
  SearchInput,
  regex,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
  SimpleShowLayout,
  ReferenceArrayField,
  ReferenceField,
  SelectInput,
  WithRecord,
  ArrayField,
  RecordContextProvider,
  useRecordContext,
  useGetMany,
  useGetList,
  defaultExporter,
  useListController,
} from "react-admin";
import { Avatar, AvatarGroup } from "@mui/material";
import {
  AvatarField,
  BooleanField,
  QuickFilter,
  DateField2,
  DateField,
  UserStatusField,
} from "../erpd";
import CommonDataGrid from "../components/CommonDataGrid";
import { customExporter } from "../utils/customExporter";

const filters = [
  <SelectInput emptyText="Show All" 
    sx={{
      ".MuiFilledInput-root": {
        borderRadius: 2,
        border: '1px solid '
      },
      ".MuiFilledInput-root::before": {
        borderWidth: 0
      },
      ".MuiFilledInput-root:hover::before": {
        borderWidth: 0
      },
      ".MuiFilledInput-root::after": {
        borderWidth: 0
      },
      ".MuiFilledInput-root:hover::after": {
        borderWidth: 0
      },
    }}
    source="status" choices={[]} alwaysOn />,
  <SearchInput
    source="q"
    placeholder="Search name, email"
    validate={regex(/^[.\w@ ]+$/, "Must be alphanumeric or email")}
    alwaysOn
  />,
  <QuickFilter
    source="is_super_admin"
    label="Super Admin"
    defaultValue={true}
  />,
  <QuickFilter source="is_admin" label="Project Admin" defaultValue={true} />,
  <QuickFilter source="is_active" label="Active" defaultValue={true} />,
];

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

const MemberList = (props) => {
  return (
    <SimpleShowLayout>
      <ReferenceManyField target="user_id" label={false} reference="members">
        <CommonDataGrid
          bulkActionButtons={false}
          rowClick="edit"
          empty={<span>No Project</span>}
          sx={{
            boxShadow: 2,
            border: 2,
            // borderColor: "primary.light",
            // "& .MuiDataGrid-cell:hover": {
            //   color: "primary.main",
            // },
          }}
        >
          <ReferenceField source="project_id" reference="projects" link="show">
            <TextField source="name" label="Project" />
          </ReferenceField>
          <TextField source="role" />
          <ReferenceArrayField
            source="groups"
            label="Groups"
            reference="groups"
          >
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
          <BooleanField source="is_active" label="Active Member" />
          {/* <EditButton /> */}
        </CommonDataGrid>
        {/* <SingleFieldList>
          <ChipField source="project_id.name" />
        </SingleFieldList> */}
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

const PostTags = () => {
  const record = useRecordContext();
  const { data, isLoading, error } = useGetList("members", {
    filter: { user_id: record.projects?.map((x) => x._id) ?? [] },
  });
  // return null;
  // if (isLoading) { return <Loading />; }
  // if (error) { return <p>ERROR</p>; }
  return data ? (
    <AvatarGroup max={2}>
      {data.map((tag) => (
        <Avatar sx={{ width: 24, height: 24 }} />
      ))}
    </AvatarGroup>
  ) : null;
};

// Define constants for custom export
// Header name should follow according to the grid title
const headerTemplate = "Email,Name,Super Admin,Project Admin,Last Login,Status,Updated At";

// Parse in the record arrangement as the display
// rowTemplate for CSV
const rowTemplate = (record) => {
  // Set the status to Active if True
  const statusLabel = record.is_active ? "Active" : "Inactive";
  return `${record.email},${record.name},${record.is_super_admin},${record.is_admin},${record.lastLoginAt},${statusLabel},${record.updatedAt}`;
};

export const UserList = (props) => {

  const { resource } = useListController(props);

  return (
    <List {...props} filters={filters}
      exporter={(records, fetchRelatedRecords) =>
        customExporter(records, fetchRelatedRecords, headerTemplate, rowTemplate, resource)
      }
    >
      <CommonDataGrid
        rowClick="edit"
      // expand={<MemberList />}
      >
        {/* <AvatarField /> */}
        <EmailField source="email" />
        <TextField source="name" />
        {/* <PostTags />
        <ArrayField source="projects">
          <SingleFieldList link={false}>
            <ReferenceField reference="projects" source="_id" link={false}>
              <WithRecord
                render={(r) => <Avatar {...stringAvatar(r.name)} />}
              />
            </ReferenceField>
          </SingleFieldList>
        </ArrayField> */}
        <BooleanField source="is_super_admin" label="Super Admin" />
        <BooleanField source="is_admin" label="Project Admin" />
        {/* <BooleanField source="is_active" label="Active" /> */}
        <DateField2 source="lastLoginAt" label="Last Login" />
        <UserStatusField source="status" />
        <DateField source="updatedAt" />
        {/* <ArrayField source="projects">
                <SingleFieldList><ChipField source="id" /></SingleFieldList>
                </ArrayField> */}
      </CommonDataGrid>
    </List>
  );
};
