import * as React from "react";
import { useState, FormEvent, ChangeEvent } from "react";
import {
  TextField,
  ReferenceField,
  DateField,
  useResourceContext,
  useDelete,
  useUpdate,
  useNotify,
  useRecordContext,
  useRefresh,
  FunctionField,
  ArrayField,
  FileField,
  ImageField,
  Confirm,
  useGetIdentity,
} from "react-admin";
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
  FilledInput,
  Button,
  Menu,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Alert,
  Link,
  Avatar,
  // Dialog,
  // DialogTitle,
  // DialogContent,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import TrashIcon from "@mui/icons-material/Delete";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';

import { DateField2, Dialog, AvatarField } from "../erpd";
import {CommentEdit} from "./CommentEdit";
import ShowAttachments from "./ShowAttachments";

// import { Status } from '../misc/Status';

export const Comment1 = ({ showStatus, note, isLast, reference, readonly = false }) => {
  const [isHover, setHover] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [noteText, setNoteText] = useState(note.message);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isConfirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const {data: {id: user_id}} = useGetIdentity();

  const resource = useResourceContext();
  const case_record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();

  const [update, { isLoading }] = useUpdate();

  // console.log(note);
  const [deleteNote, {isLoading: isConfirmDeleteLoading}] = useDelete(
    resource,
    { id: note._id, previousData: note },
    {
      mutationMode: "undoable",
      onSuccess: () => {
        notify("Note deleted", { type: "info", undoable: true });
        refresh();
      },
    }
  );

  // const handleDelete = () => {
  //   deleteNote();
  // };

  const handleEnterEditMode = () => {
    setEditing(true);
    setAnchorEl(null)
  };

  const handleCancelEdit = () => {
    setEditing(false);
    setNoteText(note.message);
    setHover(false);
  };

  const handleTextChange = (event) => {
    setNoteText(event.target.value);
  };

  const handleNoteUpdate = (event) => {
    event.preventDefault();
    update(
      resource,
      { id: note._id, data: { text: noteText }, previousData: note },
      {
        onSuccess: () => {
          setEditing(false);
          setNoteText(note.message);
          setHover(false);
        },
      }
    );
  };

  const handlePromptDelete = () => { setAnchorEl(null); setConfirmDeleteOpen(true); }
  const handleConfirmDialogClose = () => setConfirmDeleteOpen(false);
  const handleConfirmDelete = () => {
      deleteNote();
      setConfirmDeleteOpen(false);
  };

      // <CommentEdit id={record.id}
      //   renderLink={(show) => <MenuItem onClick={()=>{ show(); }}>Edit</MenuItem> }
      // />

  const ActionMenu = ({canDelete = false}) => {
    return (
    <>
      <IconButton onClick={(event)=>setAnchorEl(event.currentTarget.parentElement)}><MoreVertIcon /></IconButton>
      <Menu anchorEl={anchorEl} open={anchorEl != null} onClose={()=>setAnchorEl(null)}>
      {
        (note.createdBy == user_id) &&
        <MenuItem onClick={()=>{ setIsEditOpen(true); setAnchorEl(null);  }}>Edit</MenuItem>
      }
      {
        canDelete &&
        <MenuItem onClick={handlePromptDelete}>Delete</MenuItem>
      }
      </Menu>
      <Confirm
        isOpen={isConfirmDeleteOpen}
        loading={isConfirmDeleteLoading}
        title={`Delete post #${note && note.id}`}
        content="Are you sure you want to delete this item?"
        onConfirm={handleConfirmDelete}
        onClose={handleConfirmDialogClose}
      />
    </>
    );
  }

  return (
    <Card>
      <CardHeader
        avatar={<ReferenceField record={note} source="createdBy" reference="users" link={false}><AvatarField width="40" height="40" /></ReferenceField>}
        action={(readonly || note.deletedAt || note.createdBy!=user_id)?null:<ActionMenu canDelete={isLast && !note.status} />}
        title={note.commenter.name}
        subheader={<DateField record={note} source={"createdAt"} showTime
          locale="en-My"
          options={{
            dateStyle: "full",
            timeStyle: "short",
          }}/>}
      />
      <CardContent>
        { note.deletedAt
          ? <Alert severity="error">This comment was deleted.</Alert>
          : <Typography variant="body2" color="text.secondary" sx={{whiteSpace: 'pre'}}>{note.message}</Typography>
        }
        { (!note.deletedAt && note.updatedAt > note.createdAt) &&
          <Alert severity="warning">This comment was edited.</Alert>
        }
        { note.addressed_to &&
          <Alert severity="info">The case was re-assigned to {note.addressed_to.name}</Alert>
        }
        { note.status &&
          <Alert severity="info">{(note.previous_status === 'Completed' || note.previous_status === 'Closed')? `The case is re-opened`: `The case status was changed to ${note.status}`} </Alert>
        }
        { !note.deletedAt && <ShowAttachments images={note.images} files={note.attachments} />}
      </CardContent>
      {/*{
        (!note.deletedAt && note.attachments.length > note.images.length) &&
        <CardActions>
          {note.attachments.filter(x=>!note.images.some(y=>y.src==x.src)).map(x=><Link href={x.src} target="_blank">{x.originalname}</Link>)}
        </CardActions>
      }*/}
      <Dialog open={isEditOpen} onClose={()=>setIsEditOpen(false)} title={"Edit Comment"}>
        <CommentEdit id={note.id} onSuccess={()=>setIsEditOpen(false)} />
      </Dialog>
    </Card>
    );
  return (
    <Box
      mb={2}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Box mb={1} color="text.secondary">
        {/* <ReferenceField
                    record={note}
                    resource="contactNotes"
                    source="sales_id"
                    reference="sales"
                >
                    <TextField source="first_name" variant="body1" />
                </ReferenceField>{' '} */}
        <Typography component="span" variant="body1">
          <TextField record={note} source="commenter.name" sx={{fontWeight:'bold'}} />
          <FunctionField
            record={note}
            render={(x) => {
              return x && x.addressed_to ? (
                <span> re-assigned to <b> {x.addressed_to.name}</b></span>
              ) : null;
            }}
          />
          {", "}
          <DateField
            source="createdAt"
            record={note}
            showTime
            locales="en"
            options={{
              dateStyle: "full",
              timeStyle: "short",
            }}
          />
         
        </Typography>
        {/* {showStatus && <Status status={note.status} />} */}
        {
          note.attachments?.length !== 0 && <br />
        }
        <FileField 
          sx={{
            display: "inline-flex",
            overflow: "hidden",
            margin: 0,
            "& li": { marginRight: "30px" },
          }}
          record={note}
          source="attachments"
          title="originalname"
          src="src"
          target="_blank"
        />
        <ImageField 
          sx={{
            display: "inline-flex",
            overflow: "hidden",
            margin: 0,
            "& li": { marginRight: "30px" },
          }}
          record={note}
          source="images"
          title="originalname"
          src="src"
          target="_blank"
        />
        {/* <ArrayField record={note} source="attachments">
          <SingleFieldList>
            <FileField />
            </SingleFieldList>
        </ArrayField> */}
      </Box>
      {
        isEditing ? (
          <form onSubmit={handleNoteUpdate}>
            <FilledInput
              value={noteText}
              onChange={handleTextChange}
              fullWidth
              multiline
              sx={{
                paddingTop: "16px",
                paddingLeft: "14px",
                paddingRight: "60px",
                paddingBottom: "14px",
                lineHeight: 1.3,
              }}
              autoFocus
            />
            <Box display="flex" justifyContent="flex-end" mt={1}>
              <Button sx={{ mr: 1 }} onClick={handleCancelEdit} color="primary">
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={isLoading}
              >
                Update comment
              </Button>
            </Box>
          </form>
        ) : (
          <Box
            sx={{
              bgcolor: "#edf3f0",
              padding: "0 1em",
              borderRadius: "10px",
              display: "flex",
              alignItems: "stretch",
              marginBottom: 1,
            }}
          >
            <Box flex={1}>
              {note.message.split("\n").map((paragraph, index) => (
                <Box
                  component="p"
                  fontFamily="fontFamily"
                  fontSize="body1.fontSize"
                  lineHeight={1.3}
                  marginBottom={2.4}
                  key={index}
                >
                  {paragraph}
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                marginLeft: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                visibility: isHover ? "visible" : "hidden",
              }}
            >
              {/* <Tooltip title="Edit comment">
              <IconButton size="small" onClick={handleEnterEditMode}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete comment">
              <IconButton size="small" onClick={handleDelete}>
                <TrashIcon />
              </IconButton>
            </Tooltip> */}
            </Box>
          </Box>
        )
      }
    </Box >
  );
};
