import { useState, useEffect, useRef } from "react";
import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';
import {
    Button,
    Create,
    // DateInput,
    required,
    SaveButton,  
    SimpleForm,
    TextInput,
    Toolbar,
    useNotify,
    usePermissions,
    useRecordContext,
    useRefresh,
} from "react-admin";
// import { AssigneeInput } from "../components/AssigneeInput";

function ReopenUser({hide, ...props})
{
  const caseRecord = useRecordContext(props);
  const notify = useNotify();
  function ToolBarSend() {
        return (
          <Toolbar>
              <SaveButton label="Send for Approval" />
              <Button onClick={hide} sx={{ml:2}}>Cancel</Button>
          </Toolbar>
        )
      }
    function onSuccess(data) {
        notify(`Reopen request is successfully sent to Admin.`);
        hide();
    }
    return (
        <Create resource="reopen" record={caseRecord} redirect={false} mutationOptions={{ onSuccess }}>
      <SimpleForm toolbar={<ToolBarSend />}>
      <Typography variant="h6">Re-open Case</Typography>
      <Typography variant="subtitle2">Only a Project Admin can re-open case. The case will be re-opened once the project admin approves.</Typography>
      {/* <Typography variant="subtitle2">Only a Project Admin can re-open case. If you would like to request for re-opening this case, please contact the Project Admins using the form below.</Typography> */}
        <TextInput label="Comment" source="reopen_message" fullWidth multiline autoFocus validate={[required()]} helperText={false} />
        {/* <AssigneeInput source="assignee" label="Assignee" />
        <DateInput source="due_date" validate={[required()]} helperText={false} /> */}
      </SimpleForm>
      </Create>
    )
}

function ReopenAdmin({hide, ...props})
{
  const caseRecord = useRecordContext(props);
  const notify = useNotify();
  const refresh = useRefresh();
    function ToolBarReopen() {
          return (
            <Toolbar>
                <SaveButton label="Re-Open Case" />
                <Button onClick={hide} sx={{ml:2}}>Cancel</Button>
            </Toolbar>
          )
        }
      function onSuccess(data) {
          notify(`Case is successfully re-opened.`);
          refresh();
          hide();
      }
    caseRecord.reopen = 'admin';
  return (
        <Create resource="reopen" record={caseRecord} redirect={false} mutationOptions={{ onSuccess }}>
      <SimpleForm toolbar={<ToolBarReopen />}>
      <Typography variant="h6">Re-open Case</Typography>
        <TextInput label="Comment" source="reopen_message" fullWidth multiline autoFocus validate={[required()]} helperText={false} />
        {/* <AssigneeInput source="assignee" label="Assignee" />
        <DateInput source="due_date" validate={[required()]} helperText={false} /> */}
      </SimpleForm>
      </Create>
    )
}


export default function ReopenRequest({approval, clicks, ...props})
{
  const { isLoading, permissions } = usePermissions();
  const [ visible, setVisible ] = useState(false);
  const caseRecord = useRecordContext(props);
  const focusRef = useRef(null);
  useEffect(() => {
    setVisible(clicks > 0);
    setTimeout(()=>focusRef?.current?.scrollIntoView(), 100);
  }, [clicks]);

  if (!visible || isLoading || !caseRecord) return null;

  const isAdmin = permissions.isProjectAdmin(caseRecord.project_id);
  return (
      <Paper sx={{m:2}} elevation={3} ref={focusRef}>
      {isAdmin? <ReopenAdmin hide={()=>setVisible(false)} /> : <ReopenUser hide={()=>setVisible(false)} />}
      </Paper>
  );
}