export default (apiUrl, httpClient)=>
{
	return (
		{

			/**
			 * List cases in project with filters
			 * @param {*} params param object, expects projectid field
			 * @param {*} filters filters object
			 * @returns a promise after calling /comments/:projectId/:caseId
			 */
			 _comments_raiseComment: ({projectId, caseId}, body)=>
			 {
				 const url = `${apiUrl}/comments/${projectId}/${caseId}`;
				 return httpClient(url, {method : "POST", body : JSON.stringify(body)})
				 .then(({ headers, json }) => 
				 {
					 return {
					   data: json
					 };
				 })
				 .catch(e=>
				 {
					 return {
						 data: null,
						 error: e
					 };
				 });
			 },

			/**
			 * List cases in project with filters
			 * @param {*} params param object, expects projectid field
			 * @param {*} filters filters object
			 * @returns a promise after calling /comments/:projectId?filter=value
			 */
			_comments_getCommentsForCase: ({projectId}, filters)=>
			{
				let	queryString;

				queryString = "?";
				for (const key in filters) {
					queryString = queryString.concat(`${key}=${filters[key]}&`);
				}
				const url = `${apiUrl}/comments/${projectId}${queryString}`;
				return httpClient(url)
				.then(({ headers, json }) => 
				{
					return {
					  data: json
					};
				})
				.catch(e=>
				{
					return {
						data: null,
						error: e
					};
				});
			},
		}
	)
}