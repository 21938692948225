import {
  Edit,
  SimpleForm,
  NumberField,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  TabbedForm,
  FormTab,
  BooleanInput,
  PasswordInput,
  required,
  ReferenceArrayInput,
  useRecordContext,
  WithRecord,
  AutocompleteArrayInput,
  TopToolbar,
  ReferenceField,
  ListButton,
  TextField,
  SimpleShowLayout,
} from "react-admin";
import { TextInput } from "../erpd";
import { DepartmentInput } from "../components/DepartmentInput";
import { MemberRoles } from "./config";

export const MemberEdit = (props) => {
  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      title={<WithRecord render={(r) => <span>Member: {r.name}</span>} />}
      redirect={(resource, id, data) =>
        `projects/${data.project_id}/show/members`
      }
      actions={
        <TopToolbar style={{ minHeight: 20 }}>
          <WithRecord
            render={(record) => (
              <ListButton to={`/projects/${record.project_id}/show/members`} />
            )}
          />
        </TopToolbar>
      }
    >
      <>
        <SimpleShowLayout>
          <ReferenceField source="project_id" reference="projects" link="show">
            <WithRecord render={(record) => record.name} />
          </ReferenceField>
          <ReferenceField source="user_id" reference="users">
            <WithRecord
              render={(record) => `${record.name} <${record.email}>`}
            />
          </ReferenceField>
        </SimpleShowLayout>
        <SimpleForm>
          {/* <TextInput source="id" disabled/> */}
          {/* <TextInput source="name" disabled /> */}
          <DepartmentInput source="groups" />
          {/* <ReferenceArrayInput
          source="groups"
          reference="groups"
          // filter={{ project_id: record.project_id }}
        >
        </ReferenceArrayInput> */}

          <SelectInput
            source="role"
            label="Role"
            choices={MemberRoles}
            required
            helperText="Project admin can edit project settings and members"
          />
          <BooleanInput
            source="is_active"
            label="Active"
            helperText="Only active member can access the project"
          />
          {/* <DateInput source="__v" /> */}
        </SimpleForm>
      </>
    </Edit>
  );
};
