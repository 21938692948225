import { useState, useEffect } from "react";
import {
  TextInput,
  SimpleForm,
  EditBase,
  Toolbar,
  SaveButton,
  required,
} from "react-admin";
import { Dialog, Checkbox } from "@mui/material";
import { Close } from "@mui/icons-material"

export const CommentEdit = ({renderLink, id, onSuccess, ...props}) => {
  // const [isDialogOpen, setIsDialogOpen] = useState(false);
  // const openDialog = () => setIsDialogOpen(true);
  // const closeDialog = () => setIsDialogOpen(false);

  if (!id) return null;
  
  return (
    <>
      <EditBase resource="comments" id={id} redirect={false} mutationMode="pessimistic" mutationOptions={{onSuccess}} >
        <SimpleForm toolbar={<Toolbar><SaveButton/></Toolbar>} >
          <TextInput source="message" validate={[required()]} multiline fullWidth />
        </SimpleForm>
      </EditBase>
    </>
  );
}

    // {renderLink(openDialog)}
    // <Dialog open={isDialogOpen} onClose={closeDialog}>
    // </Dialog>
