import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function Dialog2({
  open,
  onClose,
  width = "md",
  title,
  ...props
}) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth={width} fullWidth={true}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{title}</Box>
          <Box>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      {props.children}
    </Dialog>
  );
}
