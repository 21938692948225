import { useState } from "react";
import {
  Box,
  BoxProps,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import {
  useCreate,
  useCreateSuggestionContext,
  useRecordContext,
} from "react-admin";

export const CreateAssignee = ({
  label = "Add Member by Email",
  resource,
  project_id,
}) => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [value, setValue] = useState(filter || "");
  const [disabled, setDisabled] = useState(false);
  const [create] = useCreate();
  const record = useRecordContext();

  const handleSubmit = async (event) => {
    event.preventDefault()
    setDisabled(true);
    await create(
      resource,
      {
        data: {
          name: value,
          project_id: project_id || record.project_id,
        },
      },
      {
        returnPromise: true,
        onSuccess: ({ data }) => {
          setValue("");
          onCreate(data);
          setDisabled(false);
        },
      }
    );
  };

  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            label={label}
            value={value}
            onChange={(event) => setValue(event.target.value)}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={disabled} type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
