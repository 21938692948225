import {
  Create,
  NumberField,
  ImageInput,
  ImageField,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  TabbedForm,
  FormTab,
  required,
  TopToolbar,
  ListButton,
  TextInput,
} from "react-admin";
import { SimpleForm } from "../erpd";
// import RichTextInput from "ra-input-rich-text";

const Title = ({ record }) => {
  if (!record) return null;
  return <span>Project: {record.name}</span>;
};
export const ProjectCreate = (props) => (
  <Create
    {...props}
    // title={<Title />}
    // actions={
    //   <TopToolbar>
    //     <ListButton />
    //   </TopToolbar>
    // }
  >
    <SimpleForm redirect="/">
      <TextInput source="name" label="New Project Name" validate={required()} fullWidth />
      <TextInput source="description" multiline fullWidth />
      <ImageInput source="image" label="Banner" maxSize={5*1024*1024} accept="image/*">
        <ImageField source="src" title="Banner" />
      </ImageInput>
    </SimpleForm>
    {/* <TabbedForm> */}
    {/* <FormTab label="Details"> */}

    {/* <RichTextInput source="description" /> */}
    {/* <TextInput source="created.by" /> */}
    {/* <TextInput source="status" /> */}
    {/* <SelectInput
          source="status"
          validate={required()}
          choices={[
            { id: "open", name: "Open" },
            { id: "closed", name: "Closed" },
          ]}
          defaultValue="open"
        />
        <ImageInput multiple source="pictures" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput> */}
    {/* </FormTab> */}
    {/* <FormTab label="Members">
            <ArrayInput source="members">
                <SimpleFormIterator disableReordering="true">
                    <TextInput source="name" label="User" />
                    <SelectInput  source="status" label="Role" choices={[
                { id: 'admin', name: 'Admin' },
                { id: 'member', name: 'Member' },
            ]} />
                </SimpleFormIterator>
            </ArrayInput>
            </FormTab> */}
    {/* <FormTab label="Departments">
        <ArrayInput source="groups">
          <SimpleFormIterator disableReordering="true">
            <TextInput label="" hiddenLabel />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Case Types">
        <ArrayInput source="case_types">
          <SimpleFormIterator disableReordering="true">
            <TextInput label="" variant="filled" hiddenLabel />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Case Statuses">
        <ArrayInput source="case_status_types">
          <SimpleFormIterator disableReordering="true">
            <TextInput label="" hiddenLabel />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Drawing Types">
        <ArrayInput source="drawing_types">
          <SimpleFormIterator disableReordering="true">
            <TextInput label="" hiddenLabel />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab> */}
    {/* <FormTab label="Minute Types">
        <ArrayInput source="minute_types">
          <SimpleFormIterator disableReordering="true">
            <TextInput label="" hiddenLabel />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab> */}
    {/* <DateInput source="__v" /> */}
    {/* </TabbedForm> */}
  </Create>
);
