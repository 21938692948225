import { useState } from "react";
import {
  Box,
  BoxProps,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import {
  AutocompleteArrayInput,
  Create,
  Error,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
  useCreate,
  useCreateSuggestionContext,
  useGetList,
  useRecordContext,
  SelectArrayInput,
  SelectInput,
} from "react-admin";
import { CreateTag } from "./CreateTag";

export const DepartmentInput = ({ source }) => {
  const record = useRecordContext();
  // const project = useProjectContext();
  const { isLoading, error, data } = useGetList("groups", {
    filter: { project_id: record?.project_id },
    sort: { field: "name", order: "ASC" },
  });
  if (isLoading) {
    return <span>...</span>;
    // return <Loading />;
  }
  if (error) {
    console.error("departmentInput", error);
    return null;
  }
  // console.log(data);
  return (
    <SelectInput
      // SelectArrayInput
      // resettable="true"
      // helperText="Users will be under these departments"
      sx={{
        ".MuiFilledInput-root": {
          borderRadius: 2,
          border: '1px solid '
        },
        ".MuiFilledInput-root::before": {
          borderWidth: 0
        },
        ".MuiFilledInput-root:hover::before": {
          borderWidth: 0
        },
        ".MuiFilledInput-root::after": {
          borderWidth: 0
        },
        ".MuiFilledInput-root:hover::after": {
          borderWidth: 0
        },
      }}
      label="Team"
      source={source}
      choices={data}
      createLabel="(Create...)"
      create={<CreateTag resource="groups" project_id={record.project_id} />}
    />
  );
};
