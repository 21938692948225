import {
  Edit,
  TextInput,
  BooleanInput,
  required,
  TopToolbar,
  ListButton,
  SimpleShowLayout,
  ReferenceManyField,
  Datagrid,
  ReferenceField,
  TextField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  BooleanField,
  List,
  ImageInput,
  ImageField,
  SimpleForm,
  PasswordInput,
  WithRecord,
  Toolbar,
  SaveButton,
  useRefresh,
} from "react-admin";

export const Profile = (props) => {

  const refresh = useRefresh();

  const onSuccess = () => {
    refresh();
    window.location.reload(false);
  };

  return (
    <>
      <Edit
        resource="profile"
        id="index"
        mutationMode="pessimistic"
        mutationOptions={{onSuccess}}
        title="Profile"
        redirect={false}
      >
        <WithRecord render={(record) => (
          <SimpleForm toolbar={<Toolbar><SaveButton /></Toolbar>}>
          <TextInput
            type="email"
            source="email"
            // helperText="Unique email for login and notifications"
            disabled
          />
          <TextInput
            label="Display name"
            source="name"
            validate={required()}
            disabled={record.is_ad_login}
          />
          <TextInput
            label="Role"
            source="role"
            disabled
          />
          <ImageInput source="avatar" label="Avatar" maxSize={10*1024*1024} accept="image/*">
            <ImageField source="src" title="Avatar" />
            </ImageInput>
            {
              !record.is_ad_login && (
                <>
                  <PasswordInput source="password" label="Current Password" />
                  <PasswordInput source="passwordNew" label="New Password" />
                  <PasswordInput source="passwordConfirm" label="Confirm New Password" />
                </>
              )
            }
          </SimpleForm>
        )} />
      </Edit>
    </>
  );
};
