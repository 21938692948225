import {
  Edit,
  required,
  SimpleForm,
  TextInput,
  TopToolbar,
  WithRecord,
  ListButton,
  ReferenceField,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import { useProjectContext } from "../erpd";

export const GroupEdit = () => {
  return (
    <Edit
      title={<WithRecord render={(x) => <span>Group: {x.name}</span>} />}
      // redirect={(resource, id, data) =>
      //   `projects/${data.project_id}/show/members`
      // }
      actions={
        <TopToolbar style={{ minHeight: 20 }}>
          <WithRecord
            render={(record) => (
              <ListButton to={`/projects/${record.project_id}/show/members`} />
            )}
          />
        </TopToolbar>
      }
    >
      <SimpleShowLayout>
        <ReferenceField source="project_id" reference="projects" link="show">
          <TextField source="name" label="Project" />
        </ReferenceField>
      </SimpleShowLayout>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
      </SimpleForm>
    </Edit>
  );
};
