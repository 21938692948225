import {
  Create,
  BooleanInput,
  required,
  TextInput,
  email,
  TopToolbar,
  ListButton,
} from "react-admin";
import { SimpleForm } from "../erpd";

export const UserCreate = (props) => {
  return (
    <Create
      {...props}
      actions={
        <TopToolbar style={{ minHeight: 20 }}>
          <ListButton />
        </TopToolbar>
      }
    >
      <SimpleForm>
        <TextInput
          source="email"
          validate={[required(), email()]}
          helperText="Unique email for login and notifications"
        />
        <TextInput
          label="Display name"
          source="name"
          validate={required()}
          helperText="Unique display name"
        />
        {/* <PasswordInput
        source="password_new"
        label="Password"
        validate={required()}
        helperText={false}
      />
      <PasswordInput
        source="password_confirm"
        label="Confirm Password"
        validate={required()}
        helperText={false}
      /> */}
        <BooleanInput
          source="is_admin"
          label="Project Admin"
          helperText="Project admin can create new project"
        />
        <BooleanInput
          source="is_super_admin"
          label="Super Admin"
          helperText="Super admin can manage users"
        />
        {/* <BooleanInput
          source="is_active"
          label="Active"
          helperText="Only active user can login"
        /> */}
        {/* <ImageInput multiple source="pictures" accept="image/*">
        <ImageField source="src" title="title" />
        </ImageInput> */}
      </SimpleForm>
    </Create>
  );
};
