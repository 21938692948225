import { Link, useParams } from "react-router-dom";
import { matchPath, useLocation } from "react-router";
import {
  Edit,
  SimpleForm,
  NumberField,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  FormTab,
  required,
  useRecordContext,
  TabbedShowLayout,
  Tab,
  RecordContextProvider,
  Show,
  List,
  TextField,
  Datagrid,
  WithRecord,
  CreateButton,
  TopToolbar,
  ImageInput,
  ImageField,
} from "react-admin";

import { TextInput, TabbedForm } from "../erpd";
import { CaseTypeCreate } from "./CaseTypeCreate";
import { useState } from "react";
import { CaseTypeEdit } from "./CaseTypeEdit";

// const Title = () => (
//   <WithRecord render={(record) => <span>Project: {record.name}</span>} />
// );

const Dropdown = (props) => {
  const { resource, project_id } = props;
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(null);

  const Actions = () => (
    <TopToolbar style={{ minHeight: "20px" }}>
      <CreateButton
        variant="contained"
        to={false}
        onClick={() => setOpen(true)}
      />
    </TopToolbar>
  );

  return (
    <>
      <List
        empty={false}
        title=" "
        disableSyncWithLocation
        resource={resource}
        filter={{ project_id }}
        sort={{ field: "name", order: "ASC" }}
        actions={<Actions />}
      >
        <Datagrid rowClick={(id, resource, record) => setEdit(record)}>
          <TextField source="name" />
        </Datagrid>
      </List>
      <CaseTypeCreate
        open={!!open}
        handleClose={() => setOpen(false)}
        project_id={project_id}
        resource={resource}
      />
      <CaseTypeEdit
        record={edit}
        handleClose={() => setEdit(null)}
        project_id={project_id}
        resource={resource}
      />
    </>
  );
};
export const ProjectEdit = (props) => {
  const { id: project_id } = useParams();
  const location = useLocation();
  const matchCaseTypeCreate = matchPath(
    "/case_types/create",
    location.pathname
  );
  return (
    <>
      <Edit
        {...props}
        mutationMode="pessimistic"
        // title={<Title />}
        redirect="show"
      >
        <SimpleForm>
          <TextInput source="name" validate={required()} fullWidth />
          <TextInput source="description" multiline fullWidth />
          <ImageInput
            source="image"
            label="Banner"
            maxSize={5 * 1024 * 1024}
            accept="image/*"
          >
            <ImageField source="src" title="Banner" />
          </ImageInput>
          <SelectInput
            source="status"
            validate={required()}
            choices={[
              { id: "Open", name: "Open" },
              { id: "Closed", name: "Closed" },
            ]}
          />
        </SimpleForm>
      </Edit>
      <Show actions={false} title=" ">
        <TabbedShowLayout record={{}}>
          <Tab label="Case Types">
            <Dropdown resource="case_types" project_id={project_id} />
          </Tab>
          <Tab label="Case Statuses">
            <Dropdown resource="case_statuses" project_id={project_id} />
          </Tab>
          <Tab label="Drawing Types">
            <Dropdown resource="drawing_types" project_id={project_id} />
          </Tab>
          <Tab label="Drawing Statuses">
            <Dropdown resource="drawing_statuses" project_id={project_id} />
          </Tab>
          <Tab label="Minute Statuses">
            <Dropdown resource="minute_statuses" project_id={project_id} />
          </Tab>
          <Tab label="Teams">
            <Dropdown resource="teams" project_id={project_id} />
          </Tab>
        </TabbedShowLayout>
      </Show>
    </>
  );
};
