import { Grid } from "@mui/material";
import { useRedirect } from "react-admin";
import { useNavigate, useParams } from "react-router-dom";

const OverallElement = ({
  title,
  value,
  imageSrc,
  color1,
  color2,
  ...props
}) => {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <div
        style={{
          height: 75,
          borderRadius: 15,
          backgroundImage: `linear-gradient(to right, ${color1}, ${color2})`,
          position: "relative",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          paddingLeft: 29,
          paddingRight: 29,
          display: "flex",
          cursor: "pointer",
        }}
        {...props}
      >
        <div>
          <img
            style={{
              height: 30,
              width: 30,
              marginBottom: 0,
            }}
            src={imageSrc}
          />
          <p
            style={{
              margin: 0,
              padding: 0,
              color: "#FFFFFF",
              fontWeight: "bold",
              fontSize: 14,
            }}
          >
            {title}
          </p>
        </div>
        <div>
          <p
            style={{
              margin: 0,
              padding: 0,
              color: "#FFFFFF",
              fontWeight: "bold",
              fontSize: 45,
            }}
          >
            {value}
          </p>
        </div>
      </div>
    </Grid>
  );
};

const OverallCases = ({ result }) => {
  // const redirect = useRedirect();
  const navigate = useNavigate();
  const { id: project_id } = useParams(); // differenciator between dashboard and project home
  const uri = project_id ? `/projects/${project_id}/show?` : "/cases?";
  return (
    <Grid container spacing={3} style={{ marginBottom: 20 }}>
      <OverallElement
        color1={"#F59F60"}
        color2={"#DE1515"}
        imageSrc={require("../assets/dashboard/warning.png")}
        title={"Overdue"}
        value={result.overdue}
        onClick={() =>
          navigate(
            uri + "filter=" + encodeURIComponent('{"fromto":"My","status":"Overdued"}')
          )
        }
      />
      <OverallElement
        color1={"#61B6D0"}
        color2={"#2972FF"}
        imageSrc={require("../assets/dashboard/search.png")}
        title={"Open"}
        value={result.open}
        onClick={() =>
          navigate(uri + "filter=" + encodeURIComponent('{"fromto":"My", "status":"Open"}'))
        }
      />
      <OverallElement
        color1={"silver"}
        color2={"gray"}
        imageSrc={require("../assets/dashboard/refresh.png")}
        title={"Ongoing"}
        value={result.ongoing}
        onClick={() =>
          navigate(
            uri + "filter=" + encodeURIComponent('{"ongoing":"true"}')
          )
        }
      />
      <OverallElement
        color1={"#24CB20"}
        color2={"#1B9982"}
        imageSrc={require("../assets/dashboard/tick.png")}
        title={"Completed"}
        value={result.completed}
        onClick={() =>
          navigate(
            uri + "filter=" + encodeURIComponent('{"status":"Completed"}')
          )
        }
      />
    </Grid>
  );
};

export default OverallCases;
