export default (apiUrl, httpClient)=>
{
	return (
		{
			test: (res, params) =>
			{
				return new Promise((resolve, reject) => {
					setTimeout(() => {
					resolve({
						"data" : "some data"
					});
					}, 300);
				});
			},
			
			/**
			 * Gets all cases created by suer in a single project
			 * @param {*} params param object, expects projectId field
			 * @returns a promise after calling /cases/created/:projectid
			 */
			_cases_viewAllCaseCreatedByUserSingle: ({projectId})=>
			{
				const url = `${apiUrl}/cases/created?${projectId}`;
				return httpClient(url).then(({ headers, json }) => {
					// if (!headers.has("x-total-count")) {
					//   throw new Error(
					//   "The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?"
					//   );
					// }
					return {
					  data: json,
					  total: json.doc.length,
					};
					});
			},

			/**
			 * List cases in project with filters
			 * @param {*} params param object, expects projectid field
			 * @param {*} filters filters object
			 * @returns a promise after calling /cases/:projectId?filter=value
			 */
			_cases_getManyCases: ({projectId}, filters)=>
			{
				let	queryString;

				queryString = "?";
				for (const key in filters) {
					queryString = queryString.concat(`${key}=${filters[key]}&`);
				}
				const url = `${apiUrl}/cases/${projectId}${queryString}`;
				return httpClient(url)
				.then(({ headers, json }) => 
				{
					return {
					  data: json
					};
				})
				.catch(e=>
				{
					return {
						data: null,
						error: e
					};
				});
			},

			/**
			 * 
			 * @param {*} param0 object containing projectId and case body
			 * @returns a promise after calling POST /cases/:projectId
			 */
			_cases_createNewCase: ({projectId, body})=>
			{
				const url = `${apiUrl}/cases/${projectId}`;
				return httpClient(url, {method : "POST", body : JSON.stringify(body)})
				.then(({ headers, json }) => 
				{
					return {
					  data: json
					};
				})
				.catch(e=>
				{
					return {
						data: null,
						error: e
					};
				});
			},

			/**
			 * 
			 * @param {*} param0 object containing projectId and case body
			 * @returns a promise after calling DELETE /cases/:projectId/:caseId
			 */
			
			_cases_deleteCase: ({projectId, caseId})=>
			{
				const url = `${apiUrl}/cases/${projectId}/${caseId}`;
				return httpClient(url, {method : "DELETE"})
				.then(({ json }) => 
				{
					return {
					  data: json
					};
				})
				.catch(e=>
				{
					return {
						data: null,
						error: e
					};
				});
			},

			/**
			 * 
			 * @param {*} param0 object containing projectId and case id
			 * @returns a promise after calling GET /cases/:projectId/:caseId
			 */
			
			_cases_getCase: ({projectId, caseId})=>
			{
				const url = `${apiUrl}/cases/${projectId}/${caseId}`;
				return httpClient(url, {method : "GET"})
				.then(({ json }) => 
				{
					return {
					  data: json
					};
				})
				.catch(e=>
				{
					return {
						data: null,
						error: e
					};
				});
			},

			/**
			 * 
			 * @param {*} param0 object containing projectId and case body
			 * @returns a promise after calling POST /cases/:projectId
			 */
			 _cases_updateCase: ({projectId, caseId, body})=>
			 {
				 const url = `${apiUrl}/cases/${projectId}/${caseId}`;
				 return httpClient(url, {method : "PUT", body : JSON.stringify(body)})
				 .then(({ headers, json }) => 
				 {
					 return {
					   data: json
					 };
				 })
				 .catch(e=>
				 {
					 return {
						 data: null,
						 error: e
					 };
				 });
			 },
		}
	)
}