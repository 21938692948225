import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Link,
} from "@mui/material";

export default function ShowAttachments({ images, files }) {
  return (
    <>
      {(images && images.length > 0) &&
        <ImageList cols={6} style={{ margin: "0" }}>
          {images.map((x) => (
            <ImageListItem key={x.src}>
              <img src={x.src} loading="lazy" />
              {x.mimetype && x.mimetype.startsWith("image/") ? (
                <Link href={x.src} target="_blank" download>
                  <ImageListItemBar
                    sx={{
                      background:
                        "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                        "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                    }}
                    title={x.originalname}
                    position="top"
                  />
                </Link>
              ) : (
                // Handle other file types
                <Link href={x.src} target="_blank" download>
                  <ImageListItemBar
                    sx={{
                      background:
                        "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                        "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                    }}
                    title={x.originalname}
                    position="top"
                  />
                </Link>
              )}
            </ImageListItem>
          ))}
        </ImageList>
      }
      {files && files
        .filter((x) => images && !images.some((y) => y.src === x.src))
        .map((x) => (
          <Link href={x.src} target="_blank" download sx={{ mr: 2 }}>
            {x.originalname}
          </Link>
        ))}
    </>
  );
}
