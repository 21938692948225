import {
  AppBar as RaAppBar,
  Layout as RaLayout,
  required,
  usePermissions,
  useGetOne,
} from "react-admin";
import { ReactQueryDevtools } from "react-query/devtools";
import { useLocation } from "react-router-dom";
import {
  useGetIdentity,
  Link,
  useLogout,
  useRedirect,
  useAuthState,
} from "react-admin";
import {
  BusinessCenter,
  Settings,
  Logout,
  Dashboard,
  Menu as MenuIcon,
} from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  Drawer,
  Box,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AvatarField } from "../erpd";
import { useState } from "react";
// import AppBar from "./AppBar";

function SecuredLayout(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { identity } = useGetIdentity();
  const { permissions } = usePermissions({}, { staleTime: 1000 });
  const { data, isLoading } = useGetOne("profile", { id: "projects" });
  const logout = useLogout();
  const [drawerOpen, setDrawerOpen] = useState(false);
  // const test = useSelector(state => state.admin.resources);
  const location = useLocation();
  const basePath = location.pathname.split("/")[1];
  // console.log(location);
  const useDrawerStyle =
    useMediaQuery("(max-width:900px)") || basePath === "drawings";
  const isSmallDevice = useMediaQuery("(max-width:600px)");
  const isExtraSmallDevice = useMediaQuery("(max-width:400px)");

  const [projectOpen, setProjectOpen] = useState(false);
  const projectSubMenu = [];
  const menuList = [
    {
      link: "/",
      title: "Dashboard",
      icon: Dashboard,
      onClick: () => { },
    },
    {
      // link: '/projects',
      title: "Projects",
      open: projectOpen,
      subMenu: projectSubMenu,
      icon: BusinessCenter,
      onClick: () => {
        setProjectOpen(!projectOpen);
      },
    },
  ];

  if (permissions.canCreateProject())
    projectSubMenu.push({
      link: "/projects/all",
      title: "All projects",
      color: "#D80000",
    });
  if (data)
    data.projects?.forEach((a) =>
      projectSubMenu.push({
        title: a.name,
        color: "#D80000",
        link: "/projects/" + a.id + "/show",
      })
    );
  if (permissions.canManageUser())
    menuList.push({
      link: "/users",
      title: "Users",
      icon: BusinessCenter,
      onClick: () => { },
    });

  const getMenuList = () => (
    <div>
      {menuList.map((props, i) => (
        <SideBarMenu
          key={i + ""}
          {...props}
          selected={props.link === location.pathname}
          color={props.link === location.pathname ? "#000000" : "#7D7D7D"}
          subMenu={
            props.subMenu &&
            props.subMenu.map((v) => {
              const onClick = v.onClick;
              v.onClick = () => {
                setDrawerOpen(false);
                if (!!onClick) {
                  onClick();
                }
              };
              return v;
            })
          }
          onClick={() => {
            if (!props.subMenu) {
              setDrawerOpen(false);
            }
            props.onClick();
          }}
        />
      ))}
      <SideBarMenu
        icon={Settings}
        title="Settings"
        color="#7D7D7D"
        link="/settings"
      />
      <SideBarMenu
        icon={Logout}
        title="Log Out"
        color="#7D7D7D"
        onClick={() => logout()}
      />
    </div>
  );
  return (
    <>
      {/* <RaLayout {...props} appBar={AppBar} /> */}

      <div
        style={{
          paddingTop: 20,
          paddingBottom: 20,
          marginRight: isExtraSmallDevice ? 15 : 60,
          marginLeft: isExtraSmallDevice ? 15 : 60,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Link to={"/"}>
          <img
            style={{ width: isSmallDevice ? 150 : 230 }}
            src={require("../assets/layout/Swmallerpdlogo.png")}
          />
        </Link>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box display={{ xs: "none", sm: "none", md: "block" }}>
            <p style={{ margin: 0, padding: 0, fontSize: 16, marginRight: 20 }}>
              {identity?.fullName}
            </p>
          </Box>
          {identity && (
            <>
              <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
                <AvatarField
                  record={{ name: identity.fullName, src: identity.avatar.src }}
                  width="25"
                  height="25"
                />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={anchorEl != null}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem
                  component={Link}
                  to="/settings"
                  onClick={() => setAnchorEl(null)}
                >
                  Settings
                </MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box
          display={{
            xs: "none",
            md: useDrawerStyle ? "none" : "block",
          }}
        >
          {getMenuList()}
        </Box>

        <Drawer
          width={300}
          open={drawerOpen}
          // ModalProps={{ onBackdropClick: ()=>setDrawerOpen(false)}}

          onClose={() => setDrawerOpen(false)}
        >
          {getMenuList()}
        </Drawer>

        <div
          style={{
            marginRight: useDrawerStyle ? 0 : 10,
            marginBottom: useDrawerStyle ? 0 : 50,
            marginLeft: 0,
            flex: 1,
          }}
        >
          <div
            style={{
              paddingLeft: isExtraSmallDevice ? 10 : useDrawerStyle ? 15 : 10,
              paddingRight: isExtraSmallDevice ? 10 : useDrawerStyle ? 15 : 10,
              paddingTop: 10,
              paddingBottom: 10,
              backgroundColor: "#F5F5F5",
              borderRadius: useDrawerStyle ? 0 : 30,
              position: "relative",
            }}
          >
            <Box
              style={{
                position: "absolute",
                left: 15,
                top: 10,
                cursor: "pointer",
              }}
              onClick={() => {
                setDrawerOpen(!drawerOpen);
              }}
              display={{ xs: "block", md: useDrawerStyle ? "block" : "none" }}
            >
              <MenuIcon />
            </Box>
            {props.children}
          </div>
        </div>
      </div>

      <ReactQueryDevtools
        initialIsOpen={false}
        toggleButtonProps={{ style: { width: 20, height: 30 } }}
      />
    </>
  );
}

const SideBarMenu = (props) => {
  const getChild = (childProps, fontSize, marginTop) => (
    <div
      style={{
        height: 48,
        display: "flex",
        flexDirection: "row",
        marginTop,
        alignItems: "center",
        width: 200,
        position: "relative",
      }}
    >
      {!!childProps.icon && (
        <childProps.icon
          style={{ marginLeft: 30, height: 28, color: childProps.color }}
        />
      )}
      <p
        style={{
          fontSize: fontSize,
          marginLeft: !!childProps.icon ? 20 : 30,
          fontWeight: "500",
          color: childProps.color,
        }}
      >
        {childProps.title}
      </p>

      {childProps.subMenu && (
        <div style={{ position: "absolute", right: 30 }}>
          {childProps.open ? (
            <ExpandLessIcon style={{ color: childProps.color }} />
          ) : (
            <ExpandMoreIcon style={{ color: childProps.color }} />
          )}
        </div>
      )}
    </div>
  );

  const getSubmenu = (subMenuProps, key) => {
    return (
      <div key={key}>
        {subMenuProps.link ? (
          <Link to={subMenuProps.link} onClick={subMenuProps.onClick}>
            {getChild(subMenuProps, 14, 0)}
          </Link>
        ) : (
          <div style={{ cursor: "pointer" }} onClick={subMenuProps.onClick}>
            {getChild(subMenuProps, 14, 0)}
          </div>
        )}
      </div>
    );
  };
  return (
    <>
      {props.link ? (
        <Link to={props.link} onClick={props.onClick}>
          {getChild(props, 16, 0)}
        </Link>
      ) : (
        <div style={{ cursor: "pointer" }} onClick={props.onClick}>
          {getChild(props, 16, 0)}
        </div>
      )}

      {props.subMenu && (
        <Collapse in={props.open} timeout="auto" unmountOnExit>
          {props.subMenu.map((v, i) => getSubmenu(v, i + ""))}
        </Collapse>
      )}
    </>
  );
};

function Layout(prop) {
  const { isLoading, authenticated } = useAuthState();
  const redirect = useRedirect();
  if (isLoading) {
    return null;
  }
  if (authenticated) {
    return <SecuredLayout {...prop} />;
  }
  redirect("/login");
  return null;
}

export { Layout };
