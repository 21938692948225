import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  useRefresh,
  useDataProvider,
} from "react-admin";
import { Dialog } from "@mui/material";

const validateRequired = required();

export const CaseTypeCreate = ({ resource, open, project_id, handleClose }) => {
  const refresh = useRefresh();
  // const redirect = useRedirect();

  //   const handleClose = () => {
  //     redirect("/projects/" + project_id);
  //   };

  const onSuccess = (deal) => {
    // redirect("/projects/" + project_id);
    handleClose();
    refresh(); //workaround since <List> doesnt auto-refresh somehow
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Create
        resource={resource}
        mutationOptions={{ onSuccess }}
        redirect={false}
        sx={{ width: 500, "& .RaCreate-main": { mt: 0 } }}
      >
        <SimpleForm defaultValues={{ project_id }}>
          <TextInput source="name" fullWidth validate={validateRequired} />
        </SimpleForm>
      </Create>
    </Dialog>
  );
};
