import { ReferenceField, usePermissions, useRecordContext, WithRecord, RecordRepresentation } from "react-admin";
import { Chip } from "@mui/material";

export function AssigneeField(props) {
    const {isLoading, permissions } = usePermissions();
    const record = useRecordContext();
    if (isLoading || !record) return null;
    const isMe = (permissions.projects.some(x=>x.id==record.project_id && (record.project_id == record.assignee || x.roleId==record.assignee || x.groups?.some(y=>y==record.assignee))));
    return <ReferenceField
        style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: '-webkit-box',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
        }}
        label="Assignee" reference="role_names" source="assignee" link={false}>
            {isMe && <WithRecord render={record => <Chip
                label={<RecordRepresentation />}
                size="small"
                color="primary" />}
            />}
            </ReferenceField>
}

AssigneeField.defaultProps = { label: 'Assignee' };

// <FunctionField
// sortBy="assignee"
// label="Assignee"
// render={(record) =>
//   `${record.assignee.role === "Group" ||
//     record.assignee.role === "Everyone"
//     ? "*"
//     : ""
//   }${record.assignee.name}`
// }
// />