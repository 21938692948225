import {
  Edit,
  SimpleForm,
  NumberField,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  TabbedForm,
  FormTab,
  BooleanInput,
  PasswordInput,
  required,
  ReferenceArrayInput,
  useRecordContext,
  WithRecord,
  AutocompleteArrayInput,
  AutocompleteInput,
  Create,
  ReferenceInput,
  TopToolbar,
  ListButton,
  ReferenceField,
  SimpleShowLayout,
} from "react-admin";
import { TextInput, useProjectContext } from "../erpd";
import { useQueryWithStore, Loading, Error } from "react-admin";
import { DepartmentInput } from "../components/DepartmentInput";
import { CreateTag } from "../components/CreateTag";
import { MemberRoles } from "./config";

export const MemberCreate = (props) => {
  // const project = useProjectContext();
  // const record = useRecordContext();
  // console.log("memcreate", record);

  return (
    <Create
      title="Add Member(s)"
      // record={{ project_id: "622b1cb73363ba28cb69955e", is_active: false, invites:[] }}
      {...props}
      redirect={(resource, id, data) =>
        `projects/${data.project_id}/show/members`
      }
      actions={
        <TopToolbar style={{ minHeight: 20 }}>
          <WithRecord
            render={(record) => (
              <ListButton to={`/projects/${record.project_id}/show/members`} />
            )}
          />
        </TopToolbar>
      }
    >
      <>
        <SimpleShowLayout>
          <ReferenceField
            source="project_id"
            reference="projects"
            link="show"
          />
        </SimpleShowLayout>
        <SimpleForm>
          {/* <TextInput source="id" disabled /> */}
          <ReferenceArrayInput source="users" reference="users" perPage={100} sort={{field:"name", order:"ASC"}}>
            <AutocompleteArrayInput
              label="ERPD User(s)"
              fullWidth
              optionText={(c) => `${c.name} <${c.email}>`}
              // validate={required()}
              createLabel="(Invite...)"
              helperText="Add other ERPD users to this project."
              // suggestionLimit={5}
              ListboxProps={{ style: { maxHeight: 150 } }}
              disableCloseOnSelect blurOnSelect={false}
              // create={
              //   <CreateTag
              //     resource="members"
              //     project_id={record.project_id}
              //     label="Invite"
              //   />
              // }
              // create={
              //   <WithRecord
              //     render={(record) => (
              //       <CreateTag
              //         resource="invites"
              //         project_id={record.project_id}
              //         label="Invite"
              //       />
              //     )}
              //   />
              // }
            />
          </ReferenceArrayInput>
          <ArrayInput
            source="invites"
            label="Invite new user(s)"
            // helperText="Invite new users to ERPD and this project."
          >
            <SimpleFormIterator inline disableReordering>
              <TextInput source="email" type="email" validate={required()} />
            </SimpleFormIterator>
          </ArrayInput>

          {/* <TextInput source="email" validate={required()} /> */}
          {/* <TextInput source="name" disabled /> */}
          <DepartmentInput source="groups" />
          {/* <ReferenceArrayInput
          source="groups"
          reference="groups"
          // filter={{ project_id: record.project_id }}
        >
        </ReferenceArrayInput> */}

          <SelectInput
            source="role"
            choices={MemberRoles}
            validate={required()}
            helperText="Project admin can edit project settings and members"
          />
          {/* <BooleanInput
          source="is_active"
          label="Active"
          helperText="Only active member can access the project"
          defaultValue={true}
        /> */}
        </SimpleForm>
      </>
    </Create>
  );
};
