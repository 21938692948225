import * as React from 'react';
import { Box, Stack } from '@mui/material';
import { Loading, useListContext } from 'react-admin';

import { Comment1 } from './Comment1';
// import { NewComment } from './NewComment';

export const CommentsIterator = ({
    showStatus,
    reference,
    readonly = false
}) => {
  const { data: comments, isLoading } = useListContext();
  if (isLoading) return <Loading />;
  if (!comments || comments.length == 0) return null;
  
    return (
      <>
        {/*<NewComment />*/}
        {/*<Box mt="0.5em" sx={{background:'#eee', padding: 1, paddingBottom: 1}}>*/}
        <Stack spacing={1} sx={{background:'#eee', padding: 1}}>
          {comments.map((note, index) => (
            <Comment1
              note={note}
              isLast={index === comments.length - 1}
              showStatus={showStatus}
              reference={reference}
              key={note._id}
              readonly={readonly}
            />
          ))}
        </Stack>
      </>
    );
};