import Avatar from "@mui/material/Avatar";
import { useRecordContext } from "react-admin";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
//use Username as Profile Pic
function stringAvatar(name) {
  const ar = name.split(" ");
  const letters = ar.length > 1 ? ar[0][0] + ar[1][0] : ar[0][0] + ar[0][1];
  return {
    style: {
      backgroundColor: stringToColor(name),
    },
    title: name,
    children: letters,
  };
}

export const AvatarField = ({ width = 25, height = 25, className, sx, ...prop }) => {
  const record = useRecordContext(prop);
  if (record)
    return (
      <Avatar
        sx={sx}
        // src={`${record.avatar}?size=${size}x${size}`}
        src={record.src}
        style={{ width: parseInt(width, 10), height: parseInt(height, 10) }}
        // className={className}
        // {...stringAvatar(record.name)}
        // label="hel"
      />
    );
  return null;
};

AvatarField.defaultProps = { label: 'Avatar' };
