import { useState } from "react";
import {
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Typography,
  Container,
} from "@mui/material";
import { LockOutlined as LockOutlinedIcon } from "@mui/icons-material";
import {
  useLogin,
  useNotify,
  Notification,
} from "react-admin";
import {
  StyledDiv,
  StyledAvatar,
  StyledForm,
  StyledButton,
  Copyright,
} from "./loginComponents";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function SignIn() {
  const isSmallDevice = useMediaQuery("(max-width:600px)");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const login = useLogin();
  const notify = useNotify();
  const submit = (e) => {
    e.preventDefault();
    if (!e.currentTarget.reportValidity()) return;
    setLoading(true);
    login({ username, password })
      .catch((e) => {
        console.log(e, e.error, e.message, 1)
        notify(e.message, { type: "warning" });
        setLoading(false);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <StyledDiv>
        <img
          style={{ width: isSmallDevice ? 150 : 230 }}
          src={require("../assets/layout/Swmallerpdlogo.png")}
        />
        <StyledAvatar>

          <LockOutlinedIcon />
        </StyledAvatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <StyledForm onSubmit={submit} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Login"
            name="login"
            autoComplete="username"
            autoFocus
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Password"
            name="password"
            type="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <StyledButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="submit"
            disabled={loading}
          >
            Sign In
          </StyledButton>
          <Grid container>
            <Grid item xs>
              <Link href="#/forgot" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
          <Notification />
        </StyledForm>
      </StyledDiv>
      <Copyright />
    </Container>
  );
}
