import {
  List,
  Datagrid,
  TextField,
  EmailField,
  BooleanField,
  ArrayField,
  ChipField,
  SingleFieldList,
  SearchInput,
  ReferenceArrayField,
  useRecordContext,
  EditButton,
  TopToolbar,
  FilterButton,
  CreateButton,
  ExportButton,
  CloneButton,
  required,
  useGetList,
  AutocompleteArrayInput,
  SelectInput,
  SelectArrayInput,
  AutocompleteInput,
  ReferenceField,
  ReferenceInput,
} from "react-admin";
import ContentAdd from "@mui/icons-material/Add";
import AvatarField from "../components/AvatarField";
import { MemberRoles } from "./config";
import CommonDataGrid from "../components/CommonDataGrid";
// import { DepartmentInput } from "../components/DepartmentInput";

// const DepartmentInput = ({ source, projectId }) => {
//   const record = useRecordContext();
//   const { isLoading, error, data } = useGetList("groups", {
//     filter: { project_id: projectId },
//     sort: { field: "name", order: "ASC" },
//   });
//   if (isLoading || error) {
//     return <span>...</span>;
//     // return <Loading />;
//   }
//   return  <SelectInput source={source} choices={data} />
// };

const ListActions = ({ project_id }) => {
  return (
    <TopToolbar style={{ minHeight: 20 }}>
      <FilterButton />
      <ExportButton />
      <CloneButton
        variant="contained"
        // variant="outlined"
        label="Create"
        resource="members"
        record={{ project_id }}
        icon={<ContentAdd />}
        sx={{ marginLeft: 2 }}
      />
      {/* <CreateButton /> */}
    </TopToolbar>
  );
};

export const MemberList = (props) => {
  const { id: project_id } = useRecordContext();
  if (!project_id) return null;
  const filters = [
    <SearchInput source="q" placeholder="Name" alwaysOn />,
    <ReferenceInput
      source="group"
      reference="groups"
      filter={{ project_id }}
      sort={{ field: "name", order: "ASC" }}
      alwaysOn
    >
      <SelectInput optionText="name" emptyText="Show All" label="Team" />
    </ReferenceInput>,
    <SelectInput
      emptyText="Show All"
      source="role"
      choices={MemberRoles}
      alwaysOn
    />,
    // <DepartmentInput
    //   source="group"
    //   label="Team"
    //   projectId={project_id}
    //   alwaysOn
    // />,
  ];
  return (
    <List
      {...props}
      title=" "
      // empty={false}
      disableSyncWithLocation
      resource="members"
      actions={<ListActions project_id={project_id} />}
      filter={{ project_id }}
      filters={filters}
      // sort={{ field: "name", order: "ASC" }}
    >
      <CommonDataGrid rowClick="edit">
        {/* <AvatarField /> */}
        <TextField source="name" />
        <ReferenceField
          source="user_id"
          reference="users"
          label="Email"
          link={false}
        >
          <TextField source="email" />
        </ReferenceField>
        <TextField source="role" />
        <ReferenceArrayField source="groups" label="Team" reference="groups">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <BooleanField source="is_active" label="Active" />
        {/* <EditButton /> */}
      </CommonDataGrid>
    </List>
  );
};
