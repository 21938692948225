
/**
 * @param
 * apiUrl - the root api url provided , normally blank
 * httpClient - the httpclient reactadmin provides to send requests
 */
export default (apiUrl, httpClient) => {
	return (
		{
			_project_getMembersFromProject: (params) => {
				const url = `${apiUrl}/members/${params.projectId}`;
				return httpClient(url).then(({ headers, json }) => {
					return {
						data: json,
						total: json.doc.length,
					};
				})
					.catch((e) => {
						// console.log("members ret err ", e)
						return {
							data: e,
						};
					})
			},

			_project_viewRolesForSelf: () => {
				const url = `${apiUrl}/projects/roles`;
				return httpClient(url).then(({ headers, json }) => {
					// if (!headers.has("x-total-count")) {
					//   throw new Error(
					//   "The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?"
					//   );
					// }
					return {
						data: json,
						total: json.doc.length,
					};
				});
			},

			_project_getOneProject: (params) => {
				const url = `${apiUrl}/projects/${params.projectId}`;
				return httpClient(url).then(({ headers, json }) => {
					// if (!headers.has("x-total-count")) {
					//   throw new Error(
					//   "The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?"
					//   );
					// }
					return {
						data: json,
						total: json.doc.length,
					};
				});
			},
		}

	)
}